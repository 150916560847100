import React from "react";
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCart } from 'contexts/CartProvider'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import "firebase/auth";

export default () => {

  const googleProvider = new GoogleAuthProvider();
  const history = useHistory()
  const { cart } = useCart()

  async function signInWithGoogleNew(){
    const auth = getAuth();
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        localStorage.setItem('bereach-login-account-type', 'socialite')

        if(cart.length > 0 ){
          history.push('/cart')
        } else {
          history.goBack();
        }
      }).catch((error) => {
        alert(error.message)
      });
  }

  return (
      <Button onClick={signInWithGoogleNew} style={{width:'65%'}}>
          <img src={require('./../../assets/img/gbutton.png')} style={{width:'100%'}}/>
      </Button>
  )
}