import React from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { TextField } from '@material-ui/core';
import { useCart } from 'contexts/CartProvider'

export default ({newUserData,helperText,error}) => {
    const { setNewUserData } = useCart();

    const handleCarPlate = event => {
        setNewUserData({
            ...newUserData,
            remark : `Car Plate: ${event.target.value}`
        })
    }

    return <TextField     
                variant='outlined'
                type="text" 
                label="Car Plate"
                name="carplate" 
                id="carplate"
                helperText={helperText}
                error={error}
                onChange={handleCarPlate}
                style={{width:"100%",marginTop:10}}
            />
}