import React, { useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { DateTimePicker } from "@material-ui/pickers";
import {useCart} from 'contexts/CartProvider'
import dayjs from 'dayjs'

export default ({newUserData,setSelectedTime}) => {
    const [ selectedDate, setSelectedDate ] = useState(new Date())
    const { setNewUserData } = useCart();

    const handleDateChange = async val => {
        setSelectedDate(val)
        let result = await val
        setNewUserData({
            ...newUserData,
            collectionTime : dayjs(result).toISOString()
        })
        setSelectedTime(dayjs(result).format('dddd, MMMM D, YYYY h:mm A'))
    }

    return <DateTimePicker
                autoOk
                disablePast
                id="collectionTime"
                name="collectionTime"
                label="Time to collect"
                inputVariant="outlined"
                style={{width:"100%"}}
                value={selectedDate}
                format="DD MMM YYYY - hh:mm"
                onChange={handleDateChange}
            />   
}