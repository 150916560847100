import React, { useEffect,useState } from 'react';
import { useHistory,useParams } from "react-router-dom";
import { Grid, Typography, Button, Card, CardContent } from '@material-ui/core';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useStyles } from 'styles';
import { useStore } from 'contexts/StoreProvider'
import { WhatsAppButton } from 'components/Button/WhatsAppButton'
import { PaymentProvider, usePayment } from 'contexts/PaymentProvider'
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'

const PaymentView = () =>{
  const history = useHistory()
  const classes = useStyles()
  const { paymentType, orderNo } = useParams();
  const { checkPaymentStatus } = usePayment()
  const { currentStore } = useStore()
  const [ isChecking, setIsChecking ] = useState(true);
  const [ paymentStatus, setPaymentStatus ] = useState({});
  const whatsAppNumber = _get(currentStore, ["whatsapp"], null)

  useEffect(() => {
   checkPayment()
  },[])

  const checkPayment = async () => {
    setIsChecking(true)
    const checkPayment = await checkPaymentStatus(orderNo, paymentType)
    setPaymentStatus(checkPayment)
    setIsChecking(false)
  }

  //testID 1612167219057

  if(isChecking) return <div>Loading...</div>
  
  const _renderPaymentStatus = (status,msg) => (
    <Grid container justify="center"> 
      <Grid item xs={12} sm={12} md={12} style={{marginTop:'100px',padding:'50px'}}>
        <ReceiptIcon style={{width:'100%',alignItems:'center',marginBottom:10,fontSize:50}} />
        <Typography variant="h5" align="center"> {status == 'success' ? 'Payment Success' : 'Payment Failed' }</Typography>
        <Typography variant="h6" align="center"> {msg} </Typography>
        <Typography variant="h6" align="center"> Order ID: {orderNo}</Typography>
      </Grid>
      <Card className={classes.BottomButtons}>
        <CardContent>
          <WhatsAppButton whatsAppNumber={whatsAppNumber}/>
          {/* <Button 
            variant="outlined" 
            startIcon={<HistoryIcon />}
            onClick={() => history.push('/history')}
            style={{width:'100%',marginBottom:5,backgroundColor:'#3f51b5',color:'#fff'}}
          > Check Order History 
          </Button> */}
        </CardContent>
      </Card>
    </Grid>
  )

  if(!_isEmpty(paymentStatus)){
    return _renderPaymentStatus(paymentStatus.status,paymentStatus.errorMsg)
  } else {
    return <div>Loading result....</div>
  } 

}

export default () => (
  <PaymentProvider >
    <PaymentView />
  </PaymentProvider>
)

