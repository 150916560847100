import React, { useEffect } from 'react';
import { useFirebaseAuth } from '../../contexts/FirebaseAuthProvider'
import PhoneForm from './../../components/Form/PhoneForm'
import CodeForm from './../../components/Form/CodeForm'

export default () => {
  const {isLoggedin, authFields} = useFirebaseAuth()

  useEffect(() => {
    // if(isLoggedin) history.push('/')
  },[authFields, isLoggedin])
  
  if(isLoggedin) return <div>You Have loggedin</div>
  if(authFields.authStage === "phone") return <PhoneForm />
  if(authFields.authStage === "code") return <CodeForm />
  
  return <div>Some thing wrong</div>

}

