import React from 'react';
import { Container, Grid, Link  } from '@material-ui/core';

export default() => {

  return (
    <Container>
      <Grid style={{paddingTop:50,display: 'flex',flexDirection: 'column',width:'100%',marginLeft:'20%'}}> 
        <Link href='https://play.google.com/store/apps/details?id=com.bereachorderapp'>
          <img src={require('./../../assets/img/google-play.png')} style={{width:'50%'}} />
        </Link>
        <Link href="https://apps.apple.com/my/app/bereach/id1563347966">
          <img src={require('./../../assets/img/app-store.png')} style={{width:'50%'}}/>
        </Link>
      </Grid>
    </Container>
  );
}
