import React from 'react'

export default () => {
  
  return(
    <div>
        <h4>404 - Page Not Found</h4>
        <p>Sorry page not found</p>
        <p>Please try again</p>
    </div>
  )
}