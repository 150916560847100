import React, {useState, useEffect} from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { AppBar, Drawer, Container,IconButton,Typography, Grid} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from './../../theme/styles';
import { useStore } from 'contexts/StoreProvider'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _get from 'lodash-es/get';
import _has from 'lodash-es/get';
import MenuList from './Menu';
import {SearchButton} from 'components/Search/SearchBarButton'
import {ProductDetails} from 'components/ProductDetails/ProductDetails'
import SearchModel from 'views/Search/Search'

export const NavHeader = () => {
  const classes = useStyles();
  const [ product, setProduct ] = useState(null)
  const {  setIsSearchOpen, isFirstLoad } = useStore()
  const { pathname } = useLocation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const currentPath = pathname.split("/")[1]
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return <div />;
    }
    setIsDrawerOpen(open);
  };

  const handleClickOpen = () => {
    setIsSearchOpen(true);
  };
  
  const handleOpen = async (item) => {
    setProduct(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const BackButton = () => {
    console.log(pathname, currentPath)
    switch(currentPath) {
      case "":
        return <div />
      case "orderMessage":
      case "UserDownload":
        return <ArrowBackIcon color="primary" style={{ margin: 0, alignSelf:'left',padding:10}} onClick={() => history.push('/')}/>
      case "products":
      case "cart": 
      case "history":
      case "orderoptions":
      case "UserForm":
      case "login":
        return <ArrowBackIcon color="primary" style={{ margin: 0, alignSelf:'left',padding:10}} onClick={() => history.goBack()}/>
      case "stores":
        return isFirstLoad === true
        ? <ArrowBackIcon color="primary" style={{ margin: 0, alignSelf:'left',padding:10}} onClick={() => history.push('/')} />
        : null
      default:
        return <div />
    }
  }

  const RenderBar = () => {
    switch(currentPath){
      case "stores":
        return <SearchButton handleOpen={handleClickOpen}/>
      case "orderMessage":
      case "":
      case "payment":
      case "products":
      case "cart": 
      case "history":
      case "orderoptions":
      case "UserForm":
      default:
        return <div />
    }
  }

  const TopBar = () => {
    return(
      <Grid style={{display:'flex',flexDirection:'row'}}>
        <BackButton />
        <div onClick={() => history.push('/')} className={classes.title}>
         BeReach 
        </div>
        <Grid style={{marginRight:'15px'}}>
        <React.Fragment>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer open={isDrawerOpen} anchor='top' onClose={toggleDrawer(false)}>
            <MenuList 
              toggleDrawer={toggleDrawer}
            />
          </Drawer>
        </React.Fragment>
        </Grid>
      </Grid>
    )
  }
  
  return (
    <>
      <AppBar position="sticky" style={{backgroundColor:'rgba(238, 238, 238, 0.8)',boxShadow: 'none'}}>
        <TopBar />
        <Container maxWidth='md'>
          <RenderBar />
          <SearchModel handleOpen={handleOpen}/>
        </Container>
        <ProductDetails 
          isModalOpen = {open}
          product = {product}
          handleClose = {handleClose}
        />
      </AppBar>
    </>
  );
}