import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _get from 'lodash-es/get' 
import _isEmpty from 'lodash-es/isEmpty'
import { Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Button, InputBase} from '@material-ui/core';
import { useStore } from 'contexts/StoreProvider';
import { Categories } from './../../components/Categories/Categories'
import { SearchBar } from './../../components/Search/SearchBar';
import { SearchButton } from 'components/Search/SearchBarButton'
import {ProductDetails} from './../../components/ProductDetails/ProductDetails';
import Loading from './../../components/Generals/Loading'

export default() => {
  let { storeID } = useParams();
  const history = useHistory();
  const { getStoreDetails,currentStore, isQRExpired, setIsSearchOpen, isSearchOpen, isFirstLoad, deviceOS, isDialogShow, setIsDialogShow } = useStore();
  const [ product, setProduct ] = useState(null);
  const [open, setOpen] = useState(false);
  const [ openDialog, setOpenDialog ] = useState(true)

  useEffect(() => {
    isQRExpired()
    getStoreDetails(storeID)
  },[])

  if(_isEmpty(currentStore)) return <Loading />
  if(!storeID) return <div>Store Not found</div>
  
  const handleOpen = (item) => {
    setProduct(item)
    setOpen(true)
  }; 

  const handleClickOpen = () => setIsSearchOpen(true)

  const handleClose = () => setOpen(false);

  const handleDialogClose = () => {
    setOpenDialog(false)
    setIsDialogShow(true)
  }

  const DownloadDialog = () => {
    if(isDialogShow || (deviceOS !== 'Android' || deviceOS !== 'iOS')) 
      return null      
      
      return (
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Download App"}</DialogTitle>
          <RenderDownloadLink />
        </Dialog>
      )
  }

  const Android = () => {
    return(
      <div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Download our app for easier  ewallet payment
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.bereachorderapp">
            Download
          </Button>
          <Button color="primary" onClick={handleDialogClose}>
            Continue with web
          </Button>
        </DialogActions>
      </div>
    )
  }

  const IOS = () => {
    return(
      <div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Download our app for easier  ewallet payment
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" href="https://apps.apple.com/my/app/bereach/id1563347966">
            Download
          </Button>
          <Button color="primary" onClick={handleDialogClose}>
            Continue with web
          </Button>
        </DialogActions>
      </div>
    )
  }

  const RenderDownloadLink = () => {
    if(deviceOS == "iOS") return <IOS />
    if(deviceOS == "Android") return <Android />
    return null
  }

  return (
    <>
      {isFirstLoad && <DownloadDialog />}
      <SearchButton handleOpen={handleClickOpen} />
      <Categories handleOpen={handleOpen} />
      <ProductDetails 
        isModalOpen={open}
        product={product}
        handleClose={handleClose}
      />
      <SearchBar handleOpen={handleOpen} />
    </>
  );
}
