import React, { useState,useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { CompanyProvider, useCompany } from 'contexts/CompanyProvider'
import { Typography,Grid,Card,CardContent,Dialog,DialogActions,
         DialogContent,DialogContentText,DialogTitle,Button,CardMedia 
       } from '@material-ui/core'
import { useStore } from 'contexts/StoreProvider'
import { useCart } from 'contexts/CartProvider'
import { useStyles } from './../../styles';
import _get from 'lodash-es/get'
import _isEmpty from 'lodash-es/isEmpty'
import _ from 'lodash-es';

const Company = () => {
  const classes = useStyles();
  const history = useHistory()
  const { companyList } = useCompany()
  const { getQRDetails, isFirstLoad, setIsFirstLoad } = useStore()
  const { cart, setCart } = useCart()
  const [ loading, setLoading ] = useState(false)
  const [ isOpenAlert, setIsOpenAlert] = useState(false)
  const [ merchantQR, setMerchantQR ] = useState('')

  useEffect(() => {
    setIsFirstLoad(true)
  },[isFirstLoad])

  useEffect(() => {},[merchantQR])

  const handleStore = async (qrcode=null) =>{
    if(qrcode){
      setLoading(true)
      localStorage.removeItem('qr')
      localStorage.setItem('qr', qrcode)
      localStorage.setItem('qr-exp-date', "null");
      localStorage.removeItem('cart');
      getQRDetails(qrcode)
      setLoading(false)
    }
    history.push('/stores')
  }

  const CompanyList = ({item}) => {
    return item.map((list,index) => {
      return(
        <Grid item xs={12} key={index.toString()}>
          <Card onClick={() => _.isEmpty(cart)
            ? handleStore(list.company_code)
            : handleAlert(list.company_code)
          }>
            <Grid style={{display:'flex',flexDirection:'row'}}>
              <CardMedia 
                className={classes.image} 
                // style={{borderRadius:'50%',height: '100',width: '100',}}
                component = "img"
                image ={list.main_image} 
              />
              <CardContent>
                <Typography variant='h6'> {list.display_name} </Typography>
                <Typography variant='subtitle1'> {list.description} </Typography>
                <Typography variant='subtitle1'> {list.address} </Typography>
              </CardContent>
            </Grid>
          </Card>
        </Grid>
      ) 
    })
  }

  const handleAlert = async (qrcode=null) => { 
    setMerchantQR(qrcode)
    setIsOpenAlert(true) 
  }
  
  const handleAlertClose = () => { 
    setIsOpenAlert(false) 
  }

  const handleClearCart = async () => {
    await setCart([]) 
    if(merchantQR){
      setLoading(true)
      localStorage.removeItem('qr')
      localStorage.setItem('qr', merchantQR)
      localStorage.setItem('qr-exp-date', "null");
      localStorage.removeItem('cart');
      getQRDetails(merchantQR)
      setLoading(false)
    }
    history.push('/stores')
  } 

  if(companyList?.totalResult < 1) return <Typography>No result</Typography>
  
  if(_isEmpty(companyList)) return <Typography> No data found </Typography>
  
  if(loading) return <Typography> Loading </Typography>

  return(
    <div>
      <Dialog
        open={isOpenAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Clear Cart item(s)?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Switching merchant will clear your cart, are you sure to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearCart} color="primary">
            Yes
          </Button>
          <Button onClick={handleAlertClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <CompanyList item = {companyList.result} />
    </div>
  ) 
}

export default () => {
  
  return (
    <CompanyProvider >
      <Company />
    </CompanyProvider>
  )
}