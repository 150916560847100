import React, { useEffect,useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Grid, 
        CardContent,
        Card,
        CardMedia,
        Box,
        Typography,InputBase, CardActionArea
 } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useStore } from './../../contexts/StoreProvider';
import { useStyles } from './../../theme/styles';
import _has from 'lodash-es/has';
import Search from './../../views/Search/Search'



export const StoreList = () => { 
  const { qrCodeData, loading } = useStore();
  const history = useHistory()

  useEffect(() => {
      if(_has(qrCodeData,'stores',"length") && qrCodeData.stores.length === 1){
          history.push(`/products/${qrCodeData.stores[0]._id.toString()}`)
      } 
    },[qrCodeData])

  const StoreItems = () => {
    const classes = useStyles();
 
    return qrCodeData.stores.map((store,index) => {
      return (
        <Box
          component = {Grid}
          container
          key = { index.toString() }
          justify="center"
          item xs={6} sm={4} md={3}
          spacing={0} > 
          <Link 
            className={classes.StoreTitles}
            to = {{ pathname: `/products/${store._id}`,
            state: { categoryId: store['_id'] } }}>
            <Box 
              className={ !store.isOpen ? classes.StoreListMainFade : classes.StoreListMain }
              component = {Card} boxShadow={5}  maxWidth='200px'>
              <CardMedia
                // className= {classes.StoreImage}
                className={ !store.isOpen ? classes.StoreImageFade : classes.StoreImage }
                component="img"
                height='170px'
                width= '150px'
                display='flex'
                image ={ store.logo_image } 
                alt={ store.display_name }
                title={ store.display_name } />
              <CardContent>{ store.display_name }
                <Typography style={{ color:'#878787' , fontSize: 12}}> {store.description} </Typography>
                 { !store.isOpen && <Typography variant="caption" style={{color:"red"}}>Temporary Close </Typography> }
              </CardContent>
            </Box>
          </Link>
        </Box>
      )
    })  
  }

  if(loading ) return <div>Loading....</div>
  if(!loading && (!qrCodeData.stores || qrCodeData.stores < 1)) return <div>Store not found</div>

  return (
    <>
    {/* <Search handleOpen = {handleOpen}/> */}
    <Grid 
      container
      style={{paddingTop:20}}
      item xs={12} sm={12}
      spacing={2} >
      <StoreItems 
        // qrCodeData = {qrCodeData}
      />
    </Grid>
    </>    
  )
}