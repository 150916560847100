import React, {useState,useEffect} from 'react';
import { Typography, Card, CardContent, Container } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import grey from '@material-ui/core/colors/grey';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get';
import { useCart } from './../../contexts/CartProvider';
import { useStore } from '../../contexts/StoreProvider';
import { useStyles } from './../../styles';
import { ProductDetails } from './../../components/ProductDetails/ProductDetails';
import { EmptyCart } from './../../components/Cart/EmptyCart';
import CartItem from './../../components/Cart/CartItem';
import ErrorPage from './../../components/Generals/ErrorPage'

export default (props) => {
    const classes = useStyles();
    const { cart, setOrderType, removeFromCart } = useCart();
    const { qrCodeData, isQRExpired } = useStore();
    const [ product, setProduct ] = useState(null);
    const [open, setOpen] = useState(false);
    const [ editData, setEditData ] = useState(null);
    const error = _get(props,['location','error'],null)
    const checkStatus = _get(props,['location','status'],'')

    const totalQty = cart.reduce((acc, curr) => acc + curr.quantity, 0)
    const totalPrice = cart.reduce((acc, curr) => {
      return acc + parseFloat(curr.totalPrice)
    }, 0)

    useEffect(() => {isQRExpired()},[])

    useEffect(() => {
        if(!_isEmpty(qrCodeData)){
            setOrderType(qrCodeData.type[0])
        }
    }, [qrCodeData])

    const handleOpen = (item = null) => {
        setProduct(item ? item.product : null)
        setEditData(item ? item : null)
        setOpen(true);
    }; 
    
    const handleClose = () => {
        setOpen(false);
    };

    const CartList = () => {
        return cart.map((item,index) => {
            return(
                <CartItem 
                    key = {index.toString()}
                    name = {item.name}
                    quantity = {item.quantity}
                    options = {item.options}
                    totalPrice = {item.totalPrice}
                    editCart = {<Typography className={classes.EditButton} onClick={() => handleOpen(item)}> Edit </Typography> }
                    removeFromCart = {<DeleteIcon className={classes.DeleteButton} onClick={ () => removeFromCart(item) }/>}
                />
            )
        })
    }

    const ErrorMsg = () => {
        return checkStatus == "error"
        ? ErrorPage(error)
        : <div />
    }

    if(_isEmpty(qrCodeData)) return <div />
    if ( cart.length === 0 ) return <EmptyCart />
    return(
        <Container style={{marginTop:30}}>
            <ErrorMsg />
            <Typography style={{color:grey[800]}} className={classes.FontBold}> Order Summary </Typography>
            <CartList />
            <Card style={{display:'flex',justifyContent: 'space-between', marginBottom:'30px'}}>
                <CardContent >
                    <Typography> Total Amount </Typography>
                    <Typography> Total Quantity </Typography>
                </CardContent>
                <CardContent >
                    <Typography> RM {totalPrice.toFixed(2)} </Typography>
                    <Typography className={classes.showTotalQuantity}> {totalQty} </Typography>
                </CardContent>
            </Card>
                <ProductDetails 
                    isModalOpen = {open}
                    product = {product}
                    handleClose = {handleClose}
                    editData = {editData}
                />
        </Container>
  
    )
}