import React, { useState } from 'react';
import { useApolloClient, useSubscription } from "@apollo/client";
import { CHECK_PAYMENT, WATCH_PAYMENT } from 'query'
import { useHistory } from 'react-router-dom';
import { useCart } from './CartProvider';
import { get } from 'lodash-es';

const PaymentContext = React.createContext(null);

const PaymentProvider = ({children}) => {

  const history = useHistory();
  const aoplloClient = useApolloClient();
  const { clearCart } = useCart();
  const [isPaymentLoading, setPaymentLoading] = useState(false)

  const checkPaymentStatus = async(orderNo, paymentType = "spay") => {
    try{
      setPaymentLoading(true)
      let result = await aoplloClient.query({
        query: CHECK_PAYMENT,
        variables: {orderNo, paymentType},
      })
            
      setPaymentLoading(false)
      if(result?.data?.checkPaymentStatus?.status === "success") clearCart();
      return result?.data?.checkPaymentStatus || {};
    }catch(err){
      console.log("PaymentProvider checkPaymentStatus", err)
      setPaymentLoading(false)
      throw err
    }
  }

  // Watch Data Change
  const watchPreOrder = async(paymentRefNo) => {
    try{
      aoplloClient.subscribe({
        query: WATCH_PAYMENT,
        variables: {paymentRefNo},
      })
      .subscribe(observer => {
        const isSuccess = get(observer,["data","payment","payment_status"],false);
        
        if(isSuccess) {
          history.push(`/payment/spay/${paymentRefNo}`);
        }
        else {
          alert("Your Payment Process is not successfully.");
        }
      })

    }catch(err){
      console.log("Payment Provider watchPreOrder", err)
    }
  }

  return (
    <PaymentContext.Provider 
      value={{
        isPaymentLoading,
        checkPaymentStatus, watchPreOrder
       }}
    >
      {children}
    </PaymentContext.Provider>
  )
}


const usePayment = () => {
  const ContextProvider = React.useContext(PaymentContext);
  if (ContextProvider == null) {
    throw new Error('usePayment() called outside of a PaymentContext');
  }
  return ContextProvider;
};

export {PaymentProvider, usePayment};