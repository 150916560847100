import React, {useContext, useState, useEffect} from 'react';
import { useApolloClient } from "@apollo/client";
// import {GET_COMPANIES, GET_USER} from 'query'
import { GET_COMPANIES_V2 } from 'query/companies_v2';
import _isEmpty from 'lodash-es/isEmpty';
import _isNil from 'lodash-es/isNil'
import dayjs from 'dayjs'

const CompanyContext = React.createContext(null);

const CompanyProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const [ companyList, setCompanyList ] = useState([])

  useEffect(() => {
    getCompanies()
  },[])

  const getCompanies = async () => {
      try{
        const result = await apolloClient.query({
          query: GET_COMPANIES_V2,
        })
        setCompanyList(result.data.companiesV2)
        return result
      } catch(err){
        console.log(err)
        throw err
    }
  }

  return (
    <CompanyContext.Provider 
      value={{
        getCompanies,
        companyList
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

const useCompany = () => {
  const contx = useContext(CompanyContext);
  if (contx == null) {
    throw new Error('useCompany() called outside of a CompanyProvider?');
  }
  return contx;
};

export {CompanyProvider, useCompany};