import React from 'react';
import { InputBase, Grid, AppBar, Toolbar,IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './../../theme/styles';

export default ({ handleSearch, handleClose, keyword }) => {
    const classes = useStyles();

    return(
        <AppBar className={classes.appBar}>
            <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
            </IconButton>
            <Grid className={classes.dialogSearchBar}>
                <div className={classes.dialogSearchIcon}>
                <SearchIcon />
                </div>
                <InputBase
                placeholder="Search…"
                classes={{
                    root: classes.dialogInputRoot,
                    input: classes.dialogInputInput,
                }}
                style={{color:'#fff'}}
                onChange={handleSearch}
                value= {keyword}
                inputProps={{ 'aria-label': 'search' }}
                />
            </Grid>
            </Toolbar>
        </AppBar>
    )
} 