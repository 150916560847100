import React, {useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import { Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Button} from '@material-ui/core';
import { StoreList } from '../../components/StoreList/StoreList';
import { useStyles } from './../../theme/styles';
import { useStore } from 'contexts/StoreProvider'; 
import _get from 'lodash-es/get';
import _has from 'lodash-es/get';
import _isEmpty from 'lodash-es/isEmpty';

export default () => {
  const history = useHistory()
  const { qrCodeData, deviceOS, isDialogShow, setIsDialogShow } = useStore();
  const [ openDialog, setOpenDialog ] = useState(true)
  const classes = useStyles();

  const handleClose = () => {
    setOpenDialog(false)
    setIsDialogShow(true)
  }

  const _toDownloadPage = () => history.push('/userDownload')

  const Android = () => {
    return(
      <div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Download our app for easier  ewallet payment
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.bereachorderapp">
            Download
          </Button>
          <Button color="primary" onClick={handleClose}>
            Continue with web
          </Button>
        </DialogActions>
      </div>
    )
  }

  const IOS = () => {
    return(
      <div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Download our app for easier  ewallet payment
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" href="https://apps.apple.com/my/app/bereach/id1563347966">
            Download
          </Button>
          <Button color="primary" onClick={handleClose}>
            Continue with web
          </Button>
        </DialogActions>
      </div>
    )
  }

  const RenderDownloadLink = () => {
    if(deviceOS == "iOS"){
      return <IOS />
    } else if(deviceOS == "Android"){
      return <Android />
    } else {
      return null
    }
  }

  return (
    <>
      {
        isDialogShow === false
        ? deviceOS == 'Android' || deviceOS == 'iOS'
          ? <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Download App"}</DialogTitle>
              <RenderDownloadLink />
            </Dialog>
          : null
        : null
      }
        
      <Grid style={{marginRight:'15px'}}>
      {_has(qrCodeData,'company','display_name') && 
        !_isEmpty(qrCodeData,['company','display_name'],'')
        ? <Typography className={classes.TableNo}>
           {_get(qrCodeData,['name'],'')} @ {_get(qrCodeData,['company','display_name'],'')}
          </Typography>
        : <Typography className={classes.TableNo}>
           {_get(qrCodeData,['name'],'')}
          </Typography>
      }
      </Grid>
      <StoreList />
    </>
  )
}