import React, { useEffect } from 'react';
import {Grid, Typography, Button, Card, CardContent} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2'
import firebase from 'firebase/compat/app';
import {firebaseAuth} from './../../../lib/firebase'
import {useFirebaseAuth} from './../../../contexts/FirebaseAuthProvider'
import GoogleLogin from './../../SocialLoginButton/GoogleLogin'
import FacebookLogin from './../../SocialLoginButton/FacebookLogin'

import 'react-phone-input-2/lib/material.css'

const ErrorText = ({text}) => <Typography color='error' align='right' display='block' variant='subtitle1'>{text}</Typography>

export default props => {
	const { authFields, submitPhoneNumber, setAuthFields } = useFirebaseAuth()
	const { error, isSubmitting, phone} = authFields

	useEffect(()=>{
		firebaseAuth.useDeviceLanguage();
			window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("submit-phone-button",{
				size:"invisible"
				// other options
			});
	},[])
	
		return (
			<Grid container justify="center">
			<Grid item xs={12} sm={6}><br />
			<Card>
				<CardContent>
					<Typography variant='h5'>Login</Typography>
					<Typography variant='subtitle1'>Login with phone number</Typography><br /><br />
					<PhoneInput
						enableSearch
						id='phone_number'
						country={'my'}
						value={phone}
						onChange={phone => setAuthFields({...authFields, 'phone':phone })}
						inputStyle={{width:"100%", height:'60px'}}
					/>
					
					{error && <ErrorText text={error} />}
					<br />
					<Button variant="contained" color="primary" id="submit-phone-button" size='large' fullWidth={true}
						disabled={isSubmitting}
						onClick={submitPhoneNumber}>
						{isSubmitting ? 'Please Wait ....' : 'Next' }
					</Button>

					<Grid style={{textAlign:"center",marginTop:60}}>
						<Typography>Or sign up with social account</Typography>
						<Grid style={{marginBottom:10,marginTop:10}}>
							<GoogleLogin />
						</Grid>
						{/* <Grid>
							<FacebookLogin />
						</Grid>						 */}
					</Grid>
				</CardContent>
			</Card>
			</Grid>
			</Grid>
		)
}
