import { gql } from '@apollo/client';

const QR_CODE_ATTRIBUTES = gql`
  fragment qrAttributes on QrCode {
    _id
    qr_code
    name
    store_name
    type{
      description,
      label,
      selectedBackgroundColor,
      value
    }
    payment_types{
      label
      value
    }
    company {
      _id
      address
      description
      display_name
      email
      phone
      status
      # coordinates {
      #   lng
      #   lat
      # }
      # show_coordinates
    }
    timer
    stores {
      _id
      display_name
      store_name
      company_id
      email
      tel
      website_url
      description
      logo_image
      status
      order_status
      # last_bill_number
      isOpen
    }
  }
`
export const GET_QR_DETAILS = gql`
  query ($qr_code: String){
    getStoreByQrCode(qr_code: $qr_code){
      ...qrAttributes
    }
  }${QR_CODE_ATTRIBUTES}
`;
