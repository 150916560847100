import React, {useState, useEffect} from 'react';
import { useApolloClient } from "@apollo/client";
import { SEARCH_PRODUCTS} from 'query'
import { useStore } from 'contexts/StoreProvider';
import _has from 'lodash-es/has'
import _get from 'lodash-es/get'

const ProductContext = React.createContext(null);

const ProductProvider = ({children}) => {
  const aoplloClient = useApolloClient();
  const maxSearchResult = 15
  const { qrCodeData } = useStore()
  const [ keyword, setKeyword ] = useState('')
  const [ resultLists, setResultLists ] = useState([])
  const [ currentPage, setCurrentPage ] = useState(0)

  const SearchProduct = async(keywords, page = 0) => {
    try{
      let result = await aoplloClient.query({
        query: SEARCH_PRODUCTS,
        variables: {
          keywords:keywords, 
          companyID:_get(qrCodeData,['company','_id'],''),
          limit: maxSearchResult,
          page: page
        },
      })

      return result
    }catch(err){
      console.log(err)
      throw err
    }
  }

  const searchLogic = (product) => {
    const searchName = product.name.toLowerCase().search(keyword.toLowerCase());
    const searchDescription = product.description.toLowerCase().search(keyword.toLowerCase());
    return (searchName !== -1 || searchDescription !== -1) ? true : false
  }

  const searchAllProduct =  async () => {
    const result = await SearchProduct(keyword, currentPage)
    const searchResult = _get(result,['data','searchProduct',"result"],[])
    setResultLists(resultLists.concat(searchResult))
    setCurrentPage(currentPage+1)
  }

  return (
    <ProductContext.Provider 
      value={{       
        SearchProduct,
        searchLogic,
        searchAllProduct,
        maxSearchResult,
        keyword, setKeyword,
        resultLists, setResultLists,
        currentPage, setCurrentPage
       }}
    >
      {children}
    </ProductContext.Provider>
  )
}


const useProduct = () => {
  const ContextProvider = React.useContext(ProductContext);
  if (ContextProvider == null) {
    throw new Error('useProduct() called outside of a ProductContext');
  }
  return ContextProvider;
};

export {ProductProvider, useProduct};