import { gql } from '@apollo/client';

const PROdUCTS_ATTRIBUTES = gql`
  fragment ProductAttributes on Products {
    totalResult
    result{
      _id
      store_id
      name
      sub_name
      description
      bdc_price
      price
      main_image
      brand
      option_groups
      qty
      sort
      status
      tags
      categories
      options
      images
    }
  }
`
export const SEARCH_PRODUCTS = gql`
  query ($keywords:String, $companyID: ID, $limit: Int, $page:Int){
    searchProduct(keywords:$keywords, companyID: $companyID, limit: $limit, page: $page ){
      ...ProductAttributes
    }
  }${PROdUCTS_ATTRIBUTES}
`;
