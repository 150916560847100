export default {
    APP_VERSION: "v1.2.9",
    /* Local */
    // GRAPHQL_HTTP: "http://localhost:4002/graphql",
    // GRAPHQL_WS: "ws://localhost:4002/wsql",

    /* Staging */
    // GRAPHQL_HTTP: "https://api.bereach.co/staging/order/v1/graphql",
    // GRAPHQL_WS: "wss://api.bereach.co/staging/order/v1/wsql",

    /* Production */
    GRAPHQL_HTTP: "https://api.bereach.co/order/v1/graphql",
    GRAPHQL_WS: "wss://api.bereach.co/order/v1/wsql",

    SHOW_WHATSAPP_WITHIN: 2, // show whatsApp button in history within * day(s)

    REACT_APP_SPAY_PAYMENT_TIMEOUT: 30000, // timeout pending for sarawak pay
}
