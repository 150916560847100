import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import {firebaseAuth, signOutUser} from 'lib/firebase'
import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/auth";

const FirebaseAuthContext = React.createContext(null);
const Fields = {phone: '', authStage:'phone', isSubmitting:false, error:null}

const FirebaseAuthProvider = ({children}) => {
  const history = useHistory()
  const [ AuthLoading, setAuthLoading ] = useState(false)
  const [ isLoggedin , setisLoggedin] = useState(false)
  const [ IdToken, setIdToken ] = useState(null)
  const [ authFields, setAuthFields] = useState(Fields)
  
  useEffect(() => {
    init()
          const auth = getAuth();
      console.log(auth)
  },[])

  function init() {
    firebaseAuth.onAuthStateChanged(async function(user) {
      if (user) {
        const result = await firebaseAuth.currentUser.getIdToken()
        // await localStorage.setItem('@firebaseToken', result);
        setIdToken(result)
        setAuthLoading(true)
        setisLoggedin(true)
      } else {
        // await localStorage.setItem('@firebaseToken', null);
        setIdToken(null)
        setAuthLoading(true)
        setisLoggedin(false)
      }
    });
  }

  async function submitCode (code){
    setAuthFields({...authFields,isSubmitting:true})
    try{
      const result = await window.confirmResult.confirm(code)
      setAuthFields(Fields)
      return result
    } catch(error) {
      setAuthFields({...authFields,isSubmitting:false, error:error.message})
      throw error
    }
  }

  async function submitPhoneNumber() {
    await setAuthFields(Fields)
    const phoneNumber = `+${authFields.phone}`;
    const appVerifier = window.recaptchaVerifier;

    if(phoneNumber.length > 8){
      setAuthFields({...authFields, isSubmitting:true})
      try{
        const confirmResult = await firebaseAuth.signInWithPhoneNumber(phoneNumber, appVerifier)
        window.confirmResult = confirmResult;
        setAuthFields({...authFields, authStage:'code',isSubmitting:false, error:null})
      } catch(error){
        alert(error.message)
        setAuthFields({...authFields, isSubmitting:false, error:error.message})
      }
    } else {
      setAuthFields({...authFields, isSubmitting:false, error:'Please enter your mobile number with at least 8 numbers'})
    }
  }

  async function logoutUser() {
    try{
      await signOutUser()
      alert('Successfully logged out')
      history.push('/')
    }catch(err){
      console.log(err)
    }

  }

  return (
    <FirebaseAuthContext.Provider 
      value={{
        IdToken, isLoggedin, AuthLoading, authFields, 
        setAuthFields, submitPhoneNumber, logoutUser, submitCode,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  )
}

const useFirebaseAuth = () => {
  const auth = React.useContext(FirebaseAuthContext);
  if (auth == null) {
    throw new Error('useFirebaseAuth() called outside of a AuthProvider?');
  }
  return auth;
};

export {FirebaseAuthProvider, useFirebaseAuth};