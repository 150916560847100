import React, { useState, useEffect } from 'react'
import { useApolloClient } from "@apollo/client";
import {GET_USER} from './../query'
import {useFirebaseAuth} from './FirebaseAuthProvider'

const CurrentUserContext = React.createContext(null);

const CurrentUserProvider = ({children}) => {
  const {IdToken} = useFirebaseAuth()
  const apolloClient = useApolloClient();
  const [currentUser, setCurrentUser ] = useState(null)

  useEffect(() => {
    getUserDetails()
  },[IdToken])

  async function getUserDetails() {
    if(IdToken && IdToken !== null){
      let result = await apolloClient.query({
        query: GET_USER,
      })
      setCurrentUser(result.data.user)
    } else {
      setCurrentUser(null)
    }

  }


  return (
    <CurrentUserContext.Provider 
      value={{
        currentUser
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

const useCurrentUser = () => {
  const user = React.useContext(CurrentUserContext);
  if (user == null) {
    throw new Error('CurrentUserContext() called outside of a CurrentUserProvider?');
  }
  return user;
};

export {CurrentUserProvider, useCurrentUser};