import { gql } from '@apollo/client';

const USERS_ATTRIBUTES = gql`
  fragment userAttributes on User {
    _id
    stitch_user_id
    firebase_user_id 
    email 
    full_name 
    phone 
    address 
    city 
    postcode 
    state 
    updated_date 
  }
`
export const GET_USER = gql`
  query {
    user{
     ...userAttributes
    }
  }${USERS_ATTRIBUTES}
`;

export const SAVE_USER = gql`
  mutation (
    $firebase_user_id: String
    $email: String
    $full_name: String 
    $phone: String 
    $address: String 
    $city: String 
    $postcode: String 
    $state: String 
  ){
    saveUser(input:{
      firebase_user_id: $firebase_user_id
      email: $email
      full_name: $full_name
      phone: $phone 
      address: $address 
      city: $city
      postcode: $postcode 
      state: $state 
    }){
      ...userAttributes
    }
  }${USERS_ATTRIBUTES}
`;
