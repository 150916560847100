import React from 'react';
import {OrderHistoryProvider, useOrderHistory} from 'contexts/OrderHistoryProvider'
import { HistoryList } from 'components/History/HistoryList';

const History = () => {
  const {orders} = useOrderHistory()
  if(orders.length < 1) return <div>No Order found</div>

  return(
    orders.map((order, index) => {
        return(
            <HistoryList key={index.toString()} item={order} />
        )
    })
  )
}

export default () => {
  return (
    <OrderHistoryProvider>
      <History />
    </OrderHistoryProvider>
  )
}
