import React, { useState } from 'react'
import { Paper, Dialog } from '@material-ui/core';
import ProductItem from './../Product/ProductItem'
import { useStyles } from './../../styles';
import { useStore } from 'contexts/StoreProvider';
import { useProduct } from 'contexts/ProductProvider'
import { Footer } from './../Footer/Footer'
import SearchAppBar from 'components/Search/SearchAppBar'

export const SearchBar = ({handleOpen}) => {
    const classes = useStyles();
    const { keyword, setKeyword, searchLogic } = useProduct()
    const { products,isSearchOpen,setIsSearchOpen } = useStore()
    
    const handleClose = () => {
        setIsSearchOpen(false);
        setKeyword('')
    };   
    
    const handleSearch = (event)=>{
        setKeyword(event.target.value)
    }

    const SearchItem = () => {     
        const result = products.filter((product) => searchLogic(product));
        if (result.length === 0) return <Paper className={classes.searchItem}>No product found</Paper>
        if (keyword.length === 0) return <Paper className={classes.searchItem}>Type to search</Paper>
        return (
            <Paper style={{marginTop:100,paddingLeft:20,paddingBottom:'80px'}}>
                <ProductItem currentProducts={result} handleOpen={handleOpen}/>
            </Paper>   
        )      
    }

    if(products.length === 0 ) return <div> Empty products </div>

    return (      
        <Dialog fullScreen open={isSearchOpen} onClose={handleClose} >
            <SearchAppBar 
                handleSearch = {handleSearch} 
                handleClose = {handleClose}
                keyword = {keyword} />
            <SearchItem /> 
            <Footer />              
        </Dialog>
    )
}