import React, {useState, useEffect} from 'react';
import { useStore } from './StoreProvider'
import { useApolloClient } from "@apollo/client";
import { SAVE_PRE_ORDERS } from 'query'
import {useCurrentUser} from 'contexts/CurrentUserProvider';
import _isEmpty from 'lodash-es/isEmpty'
import {fullBrowserVersion, browserName, engineName, mobileVendor, deviceType} from "react-device-detect";

import _get from 'lodash-es/get'
import uuid from 'react-uuid'
import SecureLS from 'secure-ls';

const dummyUser = {
  _id: "dummy_data",
  stitch_user_id: "",
  firebase_user_id: "",
  email: null,
  full_name: null,
  phone: null,
  address: null,
  city: null,
  postcode: null,
  state: null,
  updated_date: null,
  collectionTime: null,
}

const CartContext = React.createContext(null);

const CartProvider = ({children}) => {
  const aoplloClient = useApolloClient();
  const { currentStore, qrCodeData, isQRExpired } = useStore()
  const [ cart, setCart ] = useState([])
  const [ newUserData, setNewUserData ] = useState({})
  const [ payment, setPayment ] = useState('')
  const [ savingOrder, setSavingOrder ] = useState(false)
  const [ orderType, setOrderType ] = useState({});
  const [saveNewProfile, setSaveNewProfile] = useState(true)
  const [ errorText, setErrorText ] = useState({ helperText: '', error: false })
  var ls = new SecureLS({encodingType: 'rc4', isCompression: false});
  
  const addToCart = order => {
    isQRExpired()
    const storeIsOpen = _get(currentStore,['isOpen'],false)
    if(!storeIsOpen){
      alert('Sorry Store Close, You cannot add to cart due to the store is close')
      return
    }
    const newCart = [ ...cart, { 
      ...order, 
      id: uuid(), 
      store_id:currentStore._id,
      store_name: currentStore.display_name
    } ]
    setCart(newCart)
    ls.set('cart', JSON.stringify(newCart));
  }

  const updateCart = order => {
    const newCart = cart.reduce((acc, curr) => {
      if ( curr.id === order.id ) {
        curr = {
          ...curr,
          ...order
        }
      }
      return [ ...acc, curr ]
    }, [])
    setCart(newCart)
    ls.set('cart', JSON.stringify(newCart));
  }

  const clearCart = () => {
    setCart([])
    ls.remove('cart')
  }

  const deductFromCart = (product, option) => {
    const item = option ? option : product
    const check = cart.find(c => c.id === item.id)
    if ( check ) {
      const newCart = cart.reduce((acc, curr) => {
        if ( item.id === curr.id ) {
          curr.qty--;
        }
        return curr.qty !== 0 ? [ ...acc, curr ] : acc
      }, [])
      setCart(newCart)
      ls.set('cart', JSON.stringify(newCart));
    }
  }

  const removeFromCart = (item) => {
    const newCart = cart.reduce((acc, curr) => ( curr.id !== item.id ? [ ...acc, curr ] : acc), [])
    setCart(newCart)
    ls.set('cart', JSON.stringify(newCart));
  }

  const simplayCartItem = async () => {
    let newCart = []
    for (const item of cart){
      delete item.product
      newCart.push(item)
    }
    return newCart
  }

  // placeOrder for version 2.0
  const placeOrder = async() => {
    try{
      setErrorText({ helperText: '', error: false })
      setSavingOrder(true)
      const input = {
        user:  dummyUser,// newUserData,
        table_no : qrCodeData?.name || null, // newUserData.table_no || qrCodeData.name,
        cart: await simplayCartItem(),
        order_type_label: _get(orderType,['label'],null),
        order_type: _get(orderType,['value'],null),
        payment_type: payment, // H5-Spay payment_type value 
        save_user_profile: saveNewProfile,
        qr_code: qrCodeData.qr_code,
        qr_code_name: qrCodeData.name,
        device: `type: ${deviceType} - ${browserName} - ${fullBrowserVersion} - ${engineName} , Mobile: ${mobileVendor}`
      }

      let result = await aoplloClient.mutate({ mutation: SAVE_PRE_ORDERS, variables: { input } });

      setSavingOrder(false)

      if(result?.status !== "error") {
        if(result?.data?.savePreOrder?.status !== "H5-Spay") clearCart()
        localStorage.setItem("bereach-check-out-phone", newUserData?.phone)
      }

      return result
    }catch(err){
      console.log(err)
      throw err
    }
  }

  return (
    <CartContext.Provider 
      value={{       
        cart,
        setCart,
        addToCart, 
        updateCart,
        clearCart,
        deductFromCart,
        removeFromCart, 
        placeOrder,
        savingOrder,
        orderType,
        newUserData, 
        setNewUserData,
        setOrderType,
        saveNewProfile, setSaveNewProfile,
        payment, setPayment,
        errorText, setErrorText,
       }}
    >
      {children}
    </CartContext.Provider>
  )
}


const useCart = () => {
  const cart = React.useContext(CartContext);
  if (cart == null) {
    throw new Error('useCart() called outside of a CartProvider');
  }
  return cart;
};

export {CartProvider, useCart};