import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Button } from '@material-ui/core';

export const WhatsAppButton = ({whatsAppNumber,billNo}) => {
    if(whatsAppNumber!==null && whatsAppNumber.length > 10){
        return <Button variant="outlined" startIcon={<WhatsAppIcon />}
        onClick={() => window.location.href =`https://wa.me/${whatsAppNumber}?text=Hi I would like to enquire regarding Bill No: ${billNo}`}
        style={{width:'100%',marginBottom:5,backgroundColor:'#32B14F',color:'#fff'}}
    > WhatsApp
    </Button>
    }
    return <span />
    
}