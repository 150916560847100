import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

 
export const useStyles = makeStyles((theme) => ({
  root: {
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  StoreTitle:{
    textDecoration:"none",
    fontWeight:"bold",
  },

  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  categoryRow: {
    marginBottom: '5px',
    padding: '5px 0px',
    backgroundColor: '#000',
    fontWeight: 'bold',
  },

  /* StoreList */
  StoreListMain: {
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1,
    marginBottom: "10px",
  },

  StoreTitles: {
    textDecoration: 'none', 
    fontWeight: 'bold'
  },
  
  StoreImage: {
    height: '100',
    width: '100',
  },

  /* Search Bar */
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    margin: '5px',
    // width: '99%',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main
  },
  searchItem: {
    marginTop:100,paddingLeft:20,paddingBottom:'80px'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    backgroundColor: theme.palette.primary.main
  },
  inputRoot: {
    color: theme.palette.primary.main,
    width:'100%',
    paddingLeft: 70
  },
  inputInput: {
    marginLeft: 70,
    transition: theme.transitions.create('width'),
    width: '100%',
    color:theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  dialogSearchBar: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#3f51b5',
    marginLeft: 0,
    width: '100%',
  },
  dialogSearchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  },
  dialogInputRoot: {
    color: 'inherit',
    width:'100%',
    paddingLeft: 60
  },
  dialogInputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },

 /* Footer */
  FooterButton: {
    textDecoration: 'none', 
    color: '#fff',
    width:'100%',
    fontWeight:'bold',
    backgroundColor: '#28a745',
    padding:15,
    '&:hover': {
      backgroundColor: '#32B14F',
      color: '#FFF'
    }
  },

  FooterContainer: {
    width:'100%',
    zIndex:'20',
    position:'fixed',
    bottom:'10px',
    // background:'transparent',
    paddingTop: 50,
  },

  /* Cart */
  CartList: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden' ,
    fontStyle: 'italic',
    fontSize: '0.8rem',
    margin: '10px',
    borderBottom: '1px solid #f3f1f1',
    marginBottom: 15
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  CartItems: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '30%'
  },

  CartButton: {
    maxWidth: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  showTotalQuantity: {
    display:'flex', 
    flexDirection: 'column', 
    alignItems:'flex-end'
  },

  CartOptionTitle: {
    color: theme.palette.text.primary,
  },

  EditButton: {
    color:'#03a9f4',
    fontStyle: 'italic' ,
    cursor:'pointer'
  },

  DeleteButton: {
    color:'#ff1744', 
    cursor:'pointer'
  },

  CartProductName: {
    fontWeight: 'bold',
    color: '#000'
  },

  /* History List */
  HistoryCard: {
    display:'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },

  HistoryContent: {
    display:'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f3f1f1'
  },

  ListTotalPrice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontWeight: 'bold',
  },

  FontBold: {
    fontWeight: 'bold',
  },

  /* History Details */
  MainHistory: {
    backgroundColor:'#e8e8e8',
  },

  btnWhatsapp: {
    width: '100%',
    marginBottom: 5,
    backgroundColor: green[600],
    color: '#fff',
    '&:hover': {
      backgroundColor: '#32B14F',
      color: '#FFF'
    }
  },

  btnPhone: {
    width: '100%',
    backgroundColor: 'primary'
  },

  billNo: {
    display: 'flex',
    alignItems:'flex-end',
    flexDirection: 'column',
    width: '30%'
  },

  CardHeader: {
    marginBottom: 10
  },

  BottomCard:{
    bottom: 0,
  },

  TotalAmountLabel: {
    width: '50%',
    display: 'flex',
    alignItems:'flex-start',
    flexDirection: 'column',
    fontWeight: 'bold',
  },

  showTotalAmount: {
    width: '50%',
    display: 'flex',
    alignItems:'flex-end',
    flexDirection: 'column',
    fontWeight: 'bold',
  },

  /* NavHeader */
  menuButton: {
    alignItems: 'flex-end',
  },

  title: {
    flexGrow: 1,
    paddingTop:10,
    paddingLeft:20,      
    color: '#3f51b5',
    fontSize: 20,
    fontWeight: 'bold',
    cursor:'pointer',
    display:'flex'
  },

  CompanyTitle: {
    color: '#333',
    paddingLeft:5,
    fontWeight:'100'
  },

  titleHeader: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    color: '#000'
  },

  CompanyName: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px'
    },
      fontWeight: 'bold', fontSize: '16px', paddingBottom: theme.spacing(2),
  },

  TableNo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    },
    color: '#878787',width:'100%',display:'flex',flexDirection: 'column',alignItems: 'flex-end'
  },

  /* Product Details */
  formControl: {
    margin: theme.spacing(3),
  },
  btnAddCart: {
    display:'flex',
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center",
    width:'100%',
    padding:10,
    backgroundColor: "#2ECC71",
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#32B14F',
      color: '#FFF'
    }
  },
  ProductTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 'bold',
  },
  OptionPrice: {
    display:'flex',
    paddingRight:40,
    paddingTop:10,
    flexDirection: 'column', 
    alignItems:'flex-end',
    width:'50%'
  },

  /* Product List */
  ProductListMain: {
    overflow: "hidden",
    alignItems: "center",
    marginBottom: "10px",
  },
  ProductPaper: {
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: '100%',
  },
  image: {
    maxWidth: 120,
    height: 120,
    border: 2,
    borderRadius: 3,
  },
  categoryRows: {
    marginBottom: '5px',
    marginTop: '10px',
    padding: '10px 0px',
    paddingLeft: '10px',
    fontWeight: 'bold',
    fontSize: '30px'
  }, 

  /* Categories */
  NavTabs:{
    zIndex: 3,
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingBottom: '10px',
    paddingTop: '10px',
    color: '#000',
    /* padding: 10px; */
    borderBottom: '2px solid #939',
    '&:li': {
      marginTop: '0px',
      marginBottom: '0px',
      marginRight: '40px',
      marginLeft: '0px',
      listStyleType: 'none',
      fontWeight: 'bold',
    },
    '&:a': {
      whiteSpace: 'nowrap',
    },
  },

  isActiveTabs: {
    color: '#000',
    borderBottom: '2px solid #939',
  },

  appBarTransparent: {
    backgroundColor: '#fff'
  },
  appBarSolid: {
    backgroundColor: '#fff'
},

/* OrderComplete */
BottomButtons:{
  [theme.breakpoints.down('xs')]: {
    width:'100%'
  },
  [theme.breakpoints.up('sm')]: {
    width:'70%'
  },
  bottom:0,
  position:'absolute',
},

}));