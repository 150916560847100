import React, {useEffect} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'contexts/StoreProvider'

export default () => {
  const history = useHistory()
  const { qr } = useParams();
  const { getQRDetails } = useStore()
  
  useEffect(() => {
    if(qr){
      localStorage.setItem('qr', qr);
      localStorage.setItem('qr-exp-date', "null");
      localStorage.removeItem('cart');
      getQRDetails(qr)
    }
    history.push('/stores')
  },[qr])

  return (
    <div>
      <p>Loading ....</p>
    </div>
  )
}