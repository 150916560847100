import React from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { TextField, MenuItem } from '@material-ui/core';
import {useCart} from 'contexts/CartProvider'
import dayjs from 'dayjs'

export default ({newUserData,setSelectedTime}) => {
    const { setNewUserData } = useCart();

    const selectTime = [
        {value: 'now', label: 'Now'},
        {value: '15mins', label: '15 minutes'},
        {value: '30mins', label: '30 minutes'},
        {value: '45mins', label: '45 minutes'},
        {value: '60mins', label: '1 hour'},
    ];

    const handleDateTimeChange = async (event) => {
        let result = await event.target.value

        if(result == '15mins'){
            let time = dayjs().add(15,'m')
            setSelectedTime(dayjs(time).format('hh:mm a'))
            setNewUserData({
                ...newUserData, 
                collectionTime : dayjs(time).toISOString()
            })

        } else if(result == '30mins'){
            let time = dayjs().add(30,'m')
            setSelectedTime(dayjs(time).format('hh:mm a'))
            setNewUserData({
                ...newUserData, 
                collectionTime : dayjs(time).toISOString()
            })

        } else if(result == '45mins'){
            let time = dayjs().add(45,'m')
            setSelectedTime(dayjs(time).format('hh:mm a'))
            setNewUserData({
                ...newUserData, 
                collectionTime : dayjs(time).toISOString()
            })

        } else if(result == '60mins'){
            let time = dayjs().add(1,'h')
            setSelectedTime(dayjs(time).format('hh:mm a'))
            setNewUserData({
                ...newUserData, 
                collectionTime : dayjs(time).toISOString()
            })

        } else if(result == 'now'){
            let time = dayjs()
            setSelectedTime(dayjs(time).format('hh:mm a'))
            setNewUserData({
                ...newUserData, 
                collectionTime : dayjs(time).toISOString()
            })
        }
    }

    return <TextField
                id="selfPickUp"
                name="selfPickUp"
                variant="outlined"
                select
                label="Time to collect"
                onChange={handleDateTimeChange}
                style={{width:'100%'}}
                defaultValue={'now'}
            >
                {
                selectTime.map((time,index) => {
                    return (
                        <MenuItem key={index.toString()} value={time.value}>
                            {time.label}
                        </MenuItem>
                    )
                })
                }
            </TextField> 
}