import React, {useState, useRef, useEffect} from 'react';
import { useStyles } from './../../styles';
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Typography, Container } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import _get from 'lodash-es/get'

export default () => {
    
    return(
        <Grid container justify="center"> 
        <Grid style={{alignContent:'center',marginTop:'100px',padding:'50px'}}>
            <CircularProgress style={{alignContent:'center'}}/>
        </Grid>
        </Grid>
    )
}