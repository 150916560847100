import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import CropFreeSharpIcon from '@material-ui/icons/CropFreeSharp';


export default() => {

  return (
    <Container>
      <Grid style={{paddingTop:50}}> 
        <CropFreeSharpIcon style={{width:'100%',alignItems:'center',flexDirection:'column',marginBottom:10,fontSize:50}} />
        <Typography variant="h5" align="center"> Sorry QrCode Expired </Typography>
        <Typography align="center"> Please rescan and order</Typography>
      </Grid>
    </Container>
  );
}
