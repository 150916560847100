import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { Grid,Typography } from '@material-ui/core'
import _isEmpty from 'lodash-es/isEmpty'
import { CardMedia } from '@material-ui/core';
import { useStyles } from './../../styles';

  export default({currentProducts,handleOpen}) => {
    const classes = useStyles();
    const { pathname } = useLocation()
    const currentPath = pathname.split("/")[1]

    useEffect(() => {
      
    },[currentPath])

    const CardImage = ({image}) => {
      if(!_isEmpty(image) ){
        return <CardMedia 
        className={classes.image} 
        component = "img"
        image ={image} 
      />
      } 
      return <span />
    }

    return currentProducts.map((item,index) => (
      <Grid key={index.toString()}
        container style={{padding: 10,display:'flex',borderBottomStyle:'solid',borderColor:'#f2f2f2'}} 
        onClick={() => handleOpen(item)}
      >
        <Grid item xs={8} sm={10} >
          <Typography gutterBottom style={{ fontSize: '1rem', fontWeight: 'bold'}}>
            {item.name}
          </Typography>
          {
            !_isEmpty(item.bdc_price)
            ? <Typography variant="body2" gutterBottom style={{textDecoration: "line-through", color:'red'}}>
                RM {parseFloat(item.bdc_price).toFixed(2)}
              </Typography>
            : null
          }
          <Typography variant="body2" gutterBottom style={{ color: '#3f51b5' }}>
            RM {parseFloat(item.price).toFixed(2)}
          </Typography>

        </Grid>
        <Grid item xs={4} sm={2} display="flex" height="100">
          <CardImage image={item.main_image} />
        </Grid>
      </Grid>
    
    ))
  }


