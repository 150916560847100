import React, {useEffect} from 'react';
import { Container, Grid, Typography,List,Divider,ListItem,ListItemIcon,ListItemText } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/History';
import grey from '@material-ui/core/colors/grey';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import _get from 'lodash-es/get'
import _has from 'lodash-es/has'
import {useFirebaseAuth} from 'contexts/FirebaseAuthProvider'
import {useCurrentUser} from 'contexts/CurrentUserProvider'
import {useStore} from 'contexts/StoreProvider'
import { useStyles } from './../../styles';
import Config from 'lib/config';

export default ({toggleDrawer}) => {
  const classes = useStyles();
  const history = useHistory()
  const confirm = useConfirm();
  const {isLoggedin, logoutUser } = useFirebaseAuth()
  const {currentUser} = useCurrentUser()
  const {qrCodeData} = useStore()

  const routeToLogin = () => history.push('/login')
  const routeToHistory = () => history.push('/history')

  useEffect(() => {},[currentUser])
  
  const handleLogoutClick = () => {
    confirm({title:'Logout?', description: 'Are you sure you want to logout ?' })
    .then(() => logoutUser())
    .catch(() => console.log('not loggout'));
  };

  return (
  <div
    style={{minHeight:'500px'}}
    role="presentation"
    onClick={toggleDrawer(false)}
    onKeyDown={toggleDrawer(false)}
  >
    <div style={{margin:'20px'}}>
      <Typography color="primary" variant="h6">BeReach
      {_has(qrCodeData,'company','display_name') && 
        <span className={classes.CompanyTitle}> @ {_get(qrCodeData,['company','display_name'],'')}</span>
      }
      </Typography>
      {/* <Typography variant="subtitle1">Welcome <b>{_get(currentUser,['full_name'], 'Guest')}</b></Typography> */}
    </div>

    <ListItem button>
        <ListItemIcon><CloseIcon /></ListItemIcon>
        <ListItemText primary={"Close"} />
      </ListItem> 
    <Divider />

    <List>
      {/* {isLoggedin &&
      <ListItem button>
          <ListItemIcon><HistoryIcon /></ListItemIcon>
          <ListItemText primary={"History"} onClick={routeToHistory} />
      </ListItem> 
      } */}
      {/* <ListItem button>
        <ListItemIcon><PersonIcon /></ListItemIcon>
          {isLoggedin 
          ? <>
            <ListItemText primary={"Logout"} onClick={handleLogoutClick} /> 
            </>
            
          : <ListItemText primary={"Login"} onClick={routeToLogin} />
          }
      </ListItem>  */}

    </List>
    <Container style={{
          position:"absolute", 
          width:'100%',
          bottom:10}}>
        <Grid item style={{
          marginLeft:"auto",
          marginRight:"auto",
          backgroundColor:grey[100],
          padding:'10px',
          width:'100%'
      }}>
        <Typography variant="subtitle1">
          Want imprement <b>BeReach Order System</b> into your store? Talk to our representative now <a href="https://wa.me/60165993800">WhatsApp</a> or call<a href="tel:+60165993800"> 016 599 3800</a></Typography>
        </Grid>
        <Typography variant="caption">Version: {Config.APP_VERSION}</Typography>
    </Container>
  </div>
)}
