import React from 'react';
import {Grid, Button, Typography, FormControl, TextField, Card, CardContent} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useHistory } from 'react-router-dom';

import {useFirebaseAuth} from 'contexts/FirebaseAuthProvider'
import {useCart} from 'contexts/CartProvider'

export default () => {
  const history = useHistory()
  const {authFields, submitCode} = useFirebaseAuth()
  const {cart} = useCart()
  const {handleSubmit, errors, register} = useForm();

  const onSubmitCode = async data =>  {
    try{
      await submitCode(data.code)
      localStorage.setItem('bereach-login-account-type', 'phone')
      if(cart.length > 0){
        history.push('/cart')
      } else {
        history.push('/')
      }
    } catch(err){
      alert(err.message)
    }
  }

  return (
    <Grid container justify="center">
    <Grid item xs={12} sm={6}><br />
      <Card>
				<CardContent>
          <Typography variant='h5'>Enter OTP</Typography>
          <Typography variant='subtitle1'>Enter one-time password (OTP) sent via SMS</Typography>
            <form onSubmit={handleSubmit(onSubmitCode)} autoComplete="off" name="codeForm">
                <FormControl variant="outlined" justify="center"  style={{ marginTop: 30,width:'100%' }}>
                <TextField {...register('code', { required: true })}
                  id="code" 
                  variant="outlined"
                  label="OTP Code" 
                  autoFocus
                  helperText= {errors?.code?.type === 'required' ? "This field is required." : ''}
                  error={errors?.code?.type === 'required' ? true : false}
                />

                <br />
                  <Button variant="contained" 
                    type="submit"
                    color="primary"
                    size='large'
                    fullWidth={true}
                    disabled={authFields.isSubmitting}>
                    {authFields.isSubmitting ? 'Please Wait ....' : 'Verify' }
                  </Button>
                </FormControl>
            </form>
          </CardContent>
			  </Card>
    </Grid>
  </Grid>
  )
}