import React from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { TextField } from '@material-ui/core';

export default ({onChange, helperText, error}) => {

    return(
        <>
            <TextField 
                id='table_no'
                name='table_no'
                label="Please enter your table number"
                variant='outlined'
                onChange={onChange}
                helperText={helperText}
                error={error}
                style={{width:"100%",marginTop:10}}
            />
        </>
    )
}