import React from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button, Card, CardContent } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HistoryIcon from '@material-ui/icons/History';
import {useStore} from 'contexts/StoreProvider'
import {useCart} from 'contexts/CartProvider'
import {OrderHistoryProvider, useOrderHistory} from 'contexts/OrderHistoryProvider'
import { useStyles } from 'styles';
import {WhatsAppButton} from 'components/Button/WhatsAppButton'
import _get from 'lodash-es/get'
import _isEmpty from 'lodash-es/isEmpty'

const OrderMessageView = () => {
    const classes = useStyles()
    const history = useHistory()
    const {currentStore} = useStore()
    const {orders} = useOrderHistory()
    const whatsAppNumber = _get(currentStore, ["whatsapp"], null)
    const { newUserData } = useCart();

    return (
        <Grid container justify="center"> 
        <Grid item xs={12} sm={12} md={12} style={{marginTop:'100px',padding:'50px'}}>
            <ShoppingCartIcon style={{width:'100%',alignItems:'center',marginBottom:10,fontSize:50}} />
            <Typography variant="h5" align="center"> Order is complete </Typography>
            {!_isEmpty(newUserData.collectionDateTime)
                ? <Typography variant="h5" align="center"> Please collect before {newUserData.collectionDateTime} </Typography>
                : <div />
            }             
        </Grid>
        <Card className={classes.BottomButtons}>
                <CardContent>
                    <WhatsAppButton whatsAppNumber={whatsAppNumber} billNo={orders.bill_no}/>
                    {/* <Button 
                        variant="outlined" 
                        startIcon={<HistoryIcon />}
                        onClick={() => history.push('/history')}
                        style={{width:'100%',backgroundColor:'#3f51b5',color:'#fff'}}
                    > Check Order History 
                    </Button> */}
                </CardContent>
            </Card>
        </Grid>
    )  
}

export default () => {
    return (
        <OrderHistoryProvider>
            <OrderMessageView />
        </OrderHistoryProvider>
    )
}