import React from 'react';
import { Card, CardContent, Container } from '@material-ui/core';
import CheckoutForm from './../../components/Form/CheckoutForm/CheckoutForm'
import OrderTypeOption from './../../components/Cart/OrderTypeOption';
import PaymentTypeOption from './../../components/Cart/PaymentTypeOption';

export default () => {

    return(
        <Container style={{marginTop:30}}>
            <Card style={{marginBottom:'30px'}}>
                <CardContent >
                    <OrderTypeOption />
                </CardContent>
            </Card>
            <Card style={{marginBottom:'30px'}}>
                <CardContent >
                    <CheckoutForm />
                </CardContent>
            </Card>
            <Card style={{marginBottom:'30px'}}>
                <CardContent >
                    <PaymentTypeOption />
                </CardContent>
            </Card>
        </Container>
    )
}