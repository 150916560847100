import React, {useState, useEffect} from 'react';
import { useCurrentUser } from './../contexts/CurrentUserProvider'
import { useApolloClient } from "@apollo/client";
import { GET_ORDERS , WATCH_ORDERS } from './../query'

const OrderHistoryContext = React.createContext(null);

const OrderHistoryProvider = ({children}) => {
  const aoplloClient = useApolloClient();
  const { currentUser } = useCurrentUser()
  const [ orders, setOrders] = useState([])
  
  useEffect(() => {
    if(currentUser === null) return
    getOrderHistory()
    WatchNewOrder()
  },[currentUser])

  useEffect(() => {
    if(orders.length < 1) return
    WatchNewOrder()
  },[orders])

  const getOrderHistory = async () => {
    try{
      let query = await aoplloClient.mutate({
        mutation: GET_ORDERS,
      })
      console.log(query.data.ordersByUser.result)
      setOrders(query.data.ordersByUser.result)
    }catch(err){
      console.log("getOrderHistory", err)
    }

  };


  function WatchNewOrder(){
    // call the "subscribe" method on Apollo Client
    try{
      aoplloClient.subscribe({
        query: WATCH_ORDERS,
        variables: {},
      }).subscribe(observer => {
        _handelChangeEvent(observer.data.order)
      })
    }catch(err){
      console.log("WatchNewOrder", err)
    }
  }

  const _handelChangeEvent = (changeEvent) => {
    let cloneOrderHistory = [...orders]
    switch(changeEvent.operationType) {
      case "insert": {
        cloneOrderHistory.push(changeEvent)
        setOrders([...cloneOrderHistory])
        break;
      }
      case "update":
      case "replace": {
        cloneOrderHistory.map((history, i) => {
          if(history._id.toString() === changeEvent._id.toString()){
            cloneOrderHistory[i] = changeEvent
          }
        })
        setOrders([...cloneOrderHistory])
        break;
      }
      case "delete": {
        cloneOrderHistory.map((history, i) => {
          if(history._id.toString() === changeEvent._id.toString()){
            cloneOrderHistory.splice(i, 1)
          }
        })
        setOrders([...cloneOrderHistory])
        break;
      }
    }
  }


  return (
    <OrderHistoryContext.Provider 
      value={{       
        orders
       }}
    >
      {children}
    </OrderHistoryContext.Provider>
  )
}


const useOrderHistory = () => {
  const cart = React.useContext(OrderHistoryContext);
  if (cart == null) {
    throw new Error('useOrderHistory() called outside of a OrderHistoryProvider');
  }
  return cart;
};

export {OrderHistoryProvider, useOrderHistory};