import React from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { useCart } from 'contexts/CartProvider'
import PhoneInput from 'react-phone-input-2'

export default () => {

  const loginType = localStorage.getItem('bereach-login-account-type')
  const { newUserData, setNewUserData } = useCart();

  return (
    <PhoneInput
      enableSearch
      id='phone'
      country={'my'}
      value={newUserData?.phone || ''}
      disabled={loginType === 'phone'}
      onChange={phone => setNewUserData({ ...newUserData, phone})}
      inputStyle={{width:"100%", height:'60px'}}
      inputProps={{
          required: true,
          autoFocus: true
      }}
  />
  )
}