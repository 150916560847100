import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { NavHeader } from './components/NavHeader/NavHeader'
import Cart from './views/Cart/Cart'
import QrCode from './views/QrCode/QrCode'
import Page404 from './views/Page404/Page404'
import Stores from './views/Stores/Stores'
import Companies from './views/Companies/Companies'
import Products from './views/Products/Products'
import LoginPage from './views/Login/Login'
import OrderOptions from './views/OrderOptions/OrderOptions'
import OrderHistory from './views/OrderHistory/OrderHistory'
import OrderMessage from './views/OrderMessage/OrderMessage'
import UserForm from './views/UserForm/UserForm'
import QrExpired from 'views/QrExpired'
import Payment from 'views/Payment'
import SearchView from 'views/Search'
import UserDownload from 'views/UserDownload'
import { Footer } from './components/Footer/Footer'

// import "fontsource-roboto" 

const RouterView = () => {
  return(
    <div style={{backgroundColor:grey[200],minHeight:'100vh'}}>
    <NavHeader />
      <Suspense fallback={<p>Loading...</p>}>
        <Container maxWidth='md' disableGutters={true}>
        <div style={{paddingBottom:"110px"}}>
        <Switch>
          {/* Old  */}
          {/* <Route path="/" name="Home" render={props => <Stores {...props}/>} exact />  */}

          <Route path="/" name="Home" render={props => <Companies {...props}/>} exact />
          <Route path="/companies" name="companies" render={props => <Companies {...props}/>} exact />
          
          <Route path="/stores" name="stores" render={props => <Stores {...props}/>} exact />
          <Route path="/products/:storeID" name="products" render={props => <Products {...props}/>} exact />
          <Route path="/login" name="login" render={props => <LoginPage />} exact={true} />
          <Route path="/qr/:qr" name="QrCode" render={props => <QrCode />} exact={true} />
          <Route path="/cart" name="Cart" render={props => <Cart {...props}/>} exact />
          <Route path="/orderoptions" name="orderoptions" render={props => <OrderOptions {...props}/>} exact  />
          <Route path="/userform/:orderType" name="UserForm" render={props => <UserForm {...props}/>} exact />
          <Route path="/orderMessage" name="OrderMessage" render={props => <OrderMessage {...props}/>} exact />
          <Route path="/history" name="Order History" render={props => <OrderHistory {...props}/>} exact />  
          <Route path="/qrExpired" name="Order History" render={props => <QrExpired {...props}/>} exact /> 
          <Route path="/payment/:paymentType/:orderNo" name="Payment" render={props => <Payment {...props}/>} exact /> 
          <Route path="/search/" name="SearchView" render={props => <SearchView {...props}/>} exact /> 
          <Route path="/userDownload/" name="UserDownload" render={props => <UserDownload {...props}/>} exact /> 
          <Route path="/" name="page404" render={props => <Page404 {...props}/>} />
        </Switch> 
        </div>
        </Container>
      </Suspense> 
    <Footer />
    </div>
  )
}


export default RouterView;
