import React, { useState, useEffect } from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import { Grid, Paper, FormControl,
         FormControlLabel, FormLabel, 
         RadioGroup, Radio, Button } from '@material-ui/core';
import { useCart } from '../../contexts/CartProvider';
import { useStore } from '../../contexts/StoreProvider';

export const OrderOption = () => {
    const { qrCodeData } = useStore();
    const { setOrderType, savingOrder, cart } = useCart();

    useEffect(() => {
        if(!_isEmpty(qrCodeData)){
          setOrderType(qrCodeData.type[0])
        }
    }, [qrCodeData])

    const handleOption = newValue => {
        const selectedOption = qrCodeData.type.find(({value}) => value === newValue);
        setOrderType(selectedOption)
    }

    const ChooseOption = ({qrCodeData}) => {
        return qrCodeData.type.map((option,index) => {
        return (
            <FormControl component="fieldset" key={index.toString()}>
            <FormLabel component="legend">Choose Option</FormLabel>
                <RadioGroup 
                    aria-label="option" 
                    name={option.label} 
                    value={option.label} 
                    onChange={handleOption}>
                    <FormControlLabel value={option.label} control={<Radio />} label={option.label} />
                </RadioGroup>
            </FormControl>
        )
        })
    }

    const ChoosePayment = () => {
        return (
            <FormControl component="fieldset">
            <FormLabel component="legend">Choose Payment</FormLabel>
                <RadioGroup 
                    aria-label="payment" 
                    name="payment" 
                    // value={values} 
                    // onChange={handleChanges}
                >
                    <FormControlLabel 
                        value="cash"
                        control={<Radio />} 
                        label="Cash" />
                </RadioGroup>
            </FormControl>
        )
    }

    return(
        <Grid>
            <Paper style={{margin:'10px'}}> <ChooseOption /> </Paper>
            <Paper style={{margin:'10px'}}> <ChoosePayment /> </Paper>
            <Grid> 
                <Button type="submit" disabled={ cart.length === 0 || savingOrder }>
                    {savingOrder ? 'Saving your order' : 'Confirm & Order' }
                </Button>
            </Grid>
        </Grid>

        
    )
}