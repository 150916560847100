import React, { useEffect,useState } from 'react';
import { Paper,Dialog } from '@material-ui/core';
import { ProductProvider, useProduct } from 'contexts/ProductProvider'
import { useStore } from 'contexts/StoreProvider';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import InfiniteScroll from "react-infinite-scroll-component";
import ProductItem from 'components/Product/ProductItem'
import { Footer } from 'components/Footer/Footer'
import SearchAppBar from 'components/Search/SearchAppBar'

const SearchView = ({handleOpen}) =>{
  const { maxSearchResult, keyword, setKeyword, resultLists, 
          setResultLists, currentPage, setCurrentPage , 
          searchAllProduct } = useProduct()
  const { isSearchOpen, setIsSearchOpen } = useStore()

  useEffect(() =>{
    if(keyword.length >= 2){
      searchAllProduct()
    }
  },[keyword])

  useEffect(() => {},[isSearchOpen])

  const handleSearch = (event)=>{
    setKeyword(event.target.value)
    setResultLists([])
  }

  const handleClickClose = () => {
    setIsSearchOpen(false)
    setCurrentPage(0)
    setKeyword('')
    setResultLists([])
  }

  const RenderSearch = () => {
    return(
      <div id="scrollableDiv" style={{flex:1 , overflow: "auto" }} >
      <InfiniteScroll
        pageStart={currentPage}
        dataLength={resultLists.length}
        next={() => searchAllProduct()}
        hasMore={resultLists.length < maxSearchResult ? false : true}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <Paper style={{marginTop:80,paddingLeft:10,paddingBottom:'80px'}}>
          <ProductItem currentProducts={resultLists} handleOpen ={handleOpen} />
        </Paper>

      </InfiniteScroll>
      </div>
    )
  }

  return (
    <>
    <Dialog fullScreen open={isSearchOpen} onClose={handleClickClose} >
      <SearchAppBar 
        handleSearch = {handleSearch} 
        handleClose = {handleClickClose}
        keyword = {keyword} />
      {RenderSearch()}
      <Footer />
    </Dialog>
    </>
  )
}

export default ({handleOpen}) => (
  <ProductProvider >
    <SearchView handleOpen={handleOpen}/>
  </ProductProvider>
)