import React, {useEffect, useState} from 'react';
import _get from 'lodash-es/get'
import { Card, CardContent, Typography, Chip} from '@material-ui/core';
import { useStyles } from './../../styles';
import { HistoryDetails } from './HistoryDetails';
import dayjs from 'dayjs';

export const HistoryList = ({item}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const toggleModuleOpen = () => setOpen(!open)

    const getTableNumber = (order) => {
        const order_type = _get(order,['order_type'],'')
        if(order_type === "dine-in-table" ){
          return `Table: ${_get(order,['custom_table_no'],'')}`
        } else if(order_type === "dine-in" ) {
          return _get(order,['qrCode','name'],'')
        } else {
          return _get(order,['order_type_label'],"")
        }
    }

    const store_name = item.store_name
    const collectTime = _get(item,['customer','collectionTime'],'')
    const created_date = _get(item,['created_date'],'')
    const status = _get(item,['status'],'')
    const total_price = _get(item,['total_price'],'')
    const table_no = getTableNumber(item)
    const total_quality = _get(item,['products'],[]).length
    const badgeColor = () => {
        if(status === 'pending') return 'secondary'
        if(status === 'received') return 'primary'
        if(status === 'confirmed') return 'primary'
        if(status === 'completed') return 'primary'
        if(status === 'processing') return 'default'
        return 'default'
    }

    return(
        <>
            <Card className={classes.HistoryCard}  onClick={() => toggleModuleOpen()}>
            <CardContent>
                <Typography style={{fontWeight:'bold'}}>{store_name} - {table_no} </Typography>
                <Typography>{total_quality} item(s) • RM {total_price}</Typography>
                <Chip label={status} size="small" color={badgeColor()} style={{textTransform:'capitalize', }} />
            </CardContent>
            <CardContent>
                <Typography className={classes.ListTotalPrice}> {dayjs(created_date).format('DD MMM')} </Typography>
                <Typography> #{item.bill_no} </Typography>
            </CardContent>
            </Card>
            <HistoryDetails 
                isModalOpen={open}
                handleClose={toggleModuleOpen}
                order={item}
                table={table_no}
                whatsapp={_get(item,['store','whatsapp'],null)}
                products={item.products}
                collectTime={collectTime}
                orderType = {_get(item,['order_type'],'')}
            />
        </>
    )

}