import React, {useEffect, useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { Typography,FormControlLabel,Checkbox } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import { useCurrentUser } from 'contexts/CurrentUserProvider';
import { useCart } from 'contexts/CartProvider'
import PhoneNumberForm from './PhoneNumberForm'
import SelfPickUp from './SelfPickUpForm'
import SelfPickUpTime from './SelfPickUpTimeForm'
import SelfPickUpCarPlate from './SelfPickUpCarPlate'
import AddressForm from './AddressForm'
import PersonalInfoForm from './PersonalInfoForm'
import TableNoForm from './TableNoForm'
import dayjs from 'dayjs'

const CheckoutForm = () => {

    const phone = localStorage.getItem("bereach-check-out-phone") || '';
    const loginType = localStorage.getItem('bereach-login-account-type')
    const {orderType, newUserData, setNewUserData, saveNewProfile, setSaveNewProfile, errorText ,setErrorText} = useCart();
    const {currentUser} = useCurrentUser()
    const [ selectedTime, setSelectedTime ] = useState('')
    
    useEffect(() => {
        
        if (!_isEmpty(orderType) && currentUser){
            const autoInsetedData = loginType === "socialite"
            ? { ...currentUser, collectionTime : dayjs().toISOString(), phone }
            : { ...currentUser, collectionTime : dayjs().toISOString() }
            
            setNewUserData(autoInsetedData)

            const timeFormat = orderType.value === "self-pickup" ? "dddd, MMMM D, YYYY h:mm A" : "hh:mm a"
            setSelectedTime(dayjs().format(timeFormat))
        }
    },[orderType,currentUser])

    const handleChange = event => {
        setNewUserData({
            ...newUserData, 
            [event.target.name] : event.target.value
        })
        setErrorText({ helperText: '', error: false })
    }

    const handleCheckboxChange = (event) => {
        setSaveNewProfile(event.target.checked)
    };

    const RenderAlert = () => {
        return !_isEmpty(newUserData.collectionTime)
            ? <Alert severity="info">Please collect on {selectedTime}</Alert>
            : <Alert severity="error">Please choose a valid time</Alert>
    }

    const RenderCarPlateAlert = () => {
        return !_isEmpty(newUserData.remark)
            ? null
            : <Alert severity="error">Please enter your car plate</Alert>
    }

    const renderingSaveProfile =  (
        <FormControlLabel 
            control={<Checkbox name="saveProfile" />} 
            checked={ saveNewProfile }
            onChange={ handleCheckboxChange }
            label="Save this as my profile" 
        />
    )

    if(!currentUser) return <></>

    return(
       <>
       <Typography style={{padding:10}}> Please enter your information </Typography>
        {orderType.value === "dine-in" &&
            <>
                <PhoneNumberForm />
                <PersonalInfoForm 
                    onChange={handleChange} 
                    currentUser={currentUser} 
                    helperText={errorText.helperText} 
                    error={errorText.error}
                    setNewUserData={setNewUserData}
                    newUserData={newUserData}
                />
            </>
        }

        {orderType.value === "self-pickup-time-carplate" &&
            <>
                {loginType === 'socialite' && <PhoneNumberForm />}
                <SelfPickUpTime newUserData={newUserData} setSelectedTime={setSelectedTime} selectedTime={selectedTime}/>
                <RenderAlert />
                <SelfPickUpCarPlate newUserData={newUserData} helperText={errorText.helperText} error={errorText.error}/>
                <RenderCarPlateAlert />
                <AddressForm onChange={handleChange} helperText={errorText.helperText} error={errorText.error}/>
            </>
        }

        {orderType.value === "self-pickup-time" &&
            <>
                {loginType === 'socialite' && <PhoneNumberForm />}
                <SelfPickUpTime newUserData={newUserData} setSelectedTime={setSelectedTime} selectedTime={selectedTime} />
                <RenderAlert />
                <AddressForm onChange={handleChange} helperText={errorText.helperText} error={errorText.error}/>
            </>
        }

        {orderType.value === "self-pickup" &&
            <>
                {loginType === 'socialite' && <PhoneNumberForm />}
                <SelfPickUp newUserData={newUserData} setSelectedTime={setSelectedTime}/>
                <AddressForm onChange={handleChange} helperText={errorText.helperText} error={errorText.error}/>
            </>
        }

        {orderType.value === "take-away" &&
            <>
                {loginType === 'socialite' && <PhoneNumberForm />}
                <AddressForm onChange={handleChange} helperText={errorText.helperText} error={errorText.error} />
            </>
        }

        {orderType.value === "delivery" &&
            <>
                {loginType === 'socialite' && <PhoneNumberForm />}
                <AddressForm onChange={handleChange} helperText={errorText.helperText} error={errorText.error} />
            </>
        }

        {orderType.value === "dine-in-table" &&
            <>
                <PhoneNumberForm />
                <TableNoForm onChange={handleChange} currentUser={currentUser} helperText={errorText.helperText} error={errorText.error}/>
                <PersonalInfoForm onChange={handleChange} helperText={errorText.helperText} error={errorText.error}/>
            </>
        }
        {renderingSaveProfile}
        </>
    )
    
}

export default CheckoutForm