import React from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { TextField } from '@material-ui/core';
import { useCurrentUser } from 'contexts/CurrentUserProvider';

export default ({onChange, helperText, error}) => {
    
    const {currentUser} = useCurrentUser()

    return(
        <TextField 
            type="text"
            label="Full Name"
            name="full_name"
            id="full_name"
            variant='outlined'
            helperText={helperText}
            error={error}
            onChange={onChange}
            defaultValue={_get(currentUser,['full_name'],'')}
            autoCorrect="off" autoCapitalize="off" spellCheck={false}
            style={{width:"100%",marginTop:10}} 
        />
    )
}