import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useStyles } from './../../styles';
import Config from 'lib/config';
import { Button, Typography, Container } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useCart } from 'contexts/CartProvider';
import { useFirebaseAuth } from 'contexts/FirebaseAuthProvider';
import { usePayment } from 'contexts/PaymentProvider';
import _get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

export const Footer = () => {

    const formSP = useRef();
    const classes = useStyles();
    const history = useHistory();
    const { isLoggedin } = useFirebaseAuth();
    const { isPaymentLoading, checkPaymentStatus, watchPreOrder } = usePayment();
    const { cart, clearCart, placeOrder,orderType, newUserData } = useCart();
    const [H5SpayDetail, setH5SpayDetail] = useState({});
    const [buttonDisplay, setButtonDisplay] = useState({ paymentCheckBtn: false, checkoutBtn: true });
    const [isLoading, setIsLoading] = useState(false);

    const spayTimeout = Config.REACT_APP_SPAY_PAYMENT_TIMEOUT

    const { pathname } = useLocation()
    const currentPath = pathname.split("/")[1]
    
    const totalQty = cart.reduce((acc, curr) => acc + curr.quantity, 0)
    const totalPrice = cart.reduce((acc, curr) => {
      return acc + parseFloat(curr.totalPrice)
    }, 0)

    const goToCart = () => history.push(`/cart`)
    const goToOrderSuccess = () => history.push(`/orderMessage`)
    const handleTriggerSPayForm = () => formSP.current && formSP.current.submit()

    const handleToForm = () => {
        // if(!isLoggedin) history.push({ pathname: '/login', state: { redirect: '/cart' } })
        if(totalQty === 0) history.push(`/`)
        
        history.push(`/UserForm/${orderType.value}`)
    }

    const handleOrder = async () => {
        // if(!isLoggedin) {
        //     history.push({ pathname: '/login', state: { redirect: '/cart' } })
        // }
        try {
            // if(isEmpty(newUserData.phone)){
            //     alert("phone cannot be blank") 
            // } else {
            setIsLoading(true)
            const result = await placeOrder()
            const status = _get(result,['data',"savePreOrder", "status"],'')
            if(status === "H5-Spay"){
                const paymentDetails = _get(result,['data',"savePreOrder"],{});
                
                await watchPreOrder(paymentDetails.paymentRefNo)
                setH5SpayDetail(paymentDetails)
                setIsLoading(false)
                setButtonDisplay({ paymentCheckBtn: true, checkoutBtn: false })
                handleTriggerSPayForm()              
            } 
            else if(status === "success"){
                goToOrderSuccess()
                setIsLoading(false)
            } 
            else if(status === "error"){
                console.log('cart',cart)
                const msg = _get(result,['data',"savePreOrder", "msg"], null)
                if(msg !== null){
                    alert(msg)
                    setIsLoading(false)
                    return                    
                }
                const error = _get(result,['data',"savePreOrder", "errors"], null)
                    history.push({
                        pathname: '/cart',
                        error,status
                    }); 
                setIsLoading(false)
            }
            // }
            
        } catch(err) {
            console.log(err)
            alert(err)
        }
        setIsLoading(false)
    }

    const handleCheckPayment = async () => {
        const checkPayment = await checkPaymentStatus(H5SpayDetail.paymentRefNo);
        if(checkPayment?.status === "success") {
            history.push(`/payment/spay/${H5SpayDetail.paymentRefNo}`);
        }
        else {
            alert("Your payment is still pending.")
            setTimeout(() => setButtonDisplay({ ...buttonDisplay, checkoutBtn: true }), spayTimeout);
        }
    }

    const RouteButton = ({ clickFunc, btnText, disabled = false }) => {
        return(
        <>
        <Button onClick={clickFunc} disabled={disabled} className={classes.FooterButton}>
            <Typography align="left" style={{width:'100%'}}>{btnText}</Typography>
            <Typography align="left" style={{width:'100%',fontWeight:'bold'}}> { totalQty } items </Typography>
            <Typography align="right" style={{width:'100%', fontWeight:'bold'}}>
              RM {totalPrice.toFixed(2)}
            </Typography>
        </Button>
        {isLoading &&  <LinearProgress />}
        </>
        )
    }

    const CheckPaymentButton = () => {
        return (
            <>
            <Button style={{ marginBottom: 10 }} onClick={handleCheckPayment} disabled={isPaymentLoading} className={classes.FooterButton}>
                <Typography align="center" style={{width:'100%',fontWeight:'bold'}}>Check Payment</Typography>
            </Button>
            {isPaymentLoading &&  <LinearProgress />}
            </>
        )
    }

    const FooterRoute = () => {
        const isEmptyCart = cart.length === 0

        switch(currentPath){
            case "":
            case "products":
            return <RouteButton clickFunc={goToCart} disabled={isEmptyCart} btnText='View Basket' />
            case "cart":
            return <RouteButton clickFunc={handleToForm} disabled={isEmptyCart} btnText= 'Place Order' />
            case "UserForm":
            return(
                <>
                    {SarawakPayForm()}
                    {buttonDisplay.paymentCheckBtn && <CheckPaymentButton />}
                    {buttonDisplay.checkoutBtn && (
                        <RouteButton clickFunc={handleOrder} 
                        disabled={(isEmptyCart && isEmpty(H5SpayDetail)) || isLoading} btnText='Place Order' />
                    )}
                </>
            )
            case "orderMessage":
            case "payment":
            case "history":
            case "login":
            return <span />
            default:
            return <RouteButton clickFunc={goToCart} btnText='View Basket' />
        }
    }

    const SarawakPayForm = () => {
        return (
            <form id="spform" method="Post" target="_blank" action={H5SpayDetail?.paymentData?.data?.APIUrl} ref={formSP}>
                <input id="formData" name="formData" value={H5SpayDetail?.paymentData?.data?.encryptedData} hidden readOnly />
                <button type="submit" hidden></button>
            </form>
        )
    }

    if(currentPath === "orderMessage") return <div />
    
    return(
        <Container maxWidth={false} className={classes.FooterContainer}>
            <FooterRoute />
        </Container>
    )
}