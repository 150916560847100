// export default () => null
import React, {useState} from "react";
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFirebaseAuth } from "contexts/FirebaseAuthProvider";
import { useCart } from 'contexts/CartProvider'
import { firebaseAuth } from 'lib/firebase'
import * as firebase from "firebase/app";
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";

// import "firebase/auth";

export default () => {
    const history = useHistory()
    const { cart } = useCart()

    const facebookProvider = new FacebookAuthProvider()
    facebookProvider.addScope('user_birthday');
    facebookProvider.addScope('email');
    facebookProvider.addScope('user_photos');

    // async function signInWithFacebook (){
    //   firebaseAuth.signInWithPopup(facebookProvider).then((res) => {
    //     console.log(res.user)
    //     if(cart.length > 0 ){
    //       history.push('/cart')
    //     } else {
    //       history.push('/')
    //     }
    //   }).catch((error) => {
    //     console.log(error.message)
    //   })
    // }
    async function signInWithFacebook (){
        console.log("signInWithFacebook")
        const auth = getAuth();
        signInWithPopup(auth, facebookProvider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            console.log(user)
            console.log(accessToken)
            // ...
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = FacebookAuthProvider.credentialFromError(error);
            console.log(errorMessage)
            // ...
        });
    }
    
    return (
        <Button onClick={() => { signInWithFacebook() }}>
            <img src={require('./../../assets/img/signinfacebook.png')} style={{width:"100%"}}/>
        </Button>
    )
}