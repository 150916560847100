import { gql } from '@apollo/client';

const STORES_ATTRIBUTES = gql`
  fragment storeAttributes on Store {
    _id
    display_name
    store_name
    company_id
    email
    tel
    whatsapp
    website_url
    description
    logo_image
    images
    status
    currency_id
    order_status
    store_company_reg_no
    last_bill_number
    isOpen
    operationType
    products{
      _id
      name
      description
      bdc_price
      price
      main_image
      option_groups
      brand
      qty
      sort
      status
      tags
      categories
      options
      images
    }
    # option_groups{
    #   _id
    #   store_id
    #   group_name
    #   description
    #   max_select
    #   min_select
    #   sort
    #   options
    # }
    categories {
      _id
      name
      display_name
      sort
      images
      status
    }
  }
`
export const GET_STORE_DETAILS = gql`
  query ($storeID: ID){
    storeV2(storeID: $storeID){
      ...storeAttributes
    }
  }${STORES_ATTRIBUTES}
`;


// export const UPDATE_STORE_ISOPEN = gql`
//   mutation ($_id: ID, $isOpen:Boolean){
//     updateStoreIsOpen(_id:$_id, isOpen:$isOpen){
//       ...storeAttributes
//     }
//   }${STORES_ATTRIBUTES}
// `;
