import { gql } from '@apollo/client';

const COMPANIES_V2_ATTRIBUTES = gql`
  fragment companyAttributes on Company {
    _id
    address
    description
    display_name
    email
    phone
    status
    main_image
    company_code
    company_qr
  }
`
export const GET_COMPANIES_V2 = gql`
  query{
    companiesV2{
      totalResult
      result{
        ...companyAttributes
      }
    }
  }${COMPANIES_V2_ATTRIBUTES}
`;

