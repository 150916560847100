import { gql } from '@apollo/client';

// const ORDERS_ATTRIBUTES = gql`
//   fragment orderAttributes on PreOrder {
//     _id
//   }
// `
export const SAVE_PRE_ORDERS = gql`
  mutation ($input:PreOrderInput){
    savePreOrder(input:$input)
  }
`;


