// import firebase from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const  firebaseConfig = {
    apiKey: "AIzaSyB-SyuZk1-f45gjA_fDyEx1rjHBPsiTZr4",
    authDomain: "bereach-co.firebaseapp.com",
    projectId: "bereach-co",
    storageBucket: "bereach-co.appspot.com",
    messagingSenderId: "1045887168780",
    appId: "1:1045887168780:web:fc0338c18e4e54acbdd929",
    measurementId: "G-FYR71P7KEK"
  };

firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
export const signOutUser = async () => {
  try{
    await firebaseAuth.signOut()
    localStorage.removeItem("bereach-login-account-type")
  } catch(e) {
    console.log(e)
  }
}