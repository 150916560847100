import React, {useState, useRef, useEffect} from 'react';
import { useStyles } from './../../styles';
import { Grid, Typography } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import _get from 'lodash-es/get'
import Alert from '@material-ui/lab/Alert';

export default (err) => {
    
    const OutOfStock = (productName) => {
        return(
            <Alert severity="error">
                <strong>{productName}</strong> is Out of Stock - Please remove the invalid product
            </Alert>
        )
    }

    const StoreClosed = (productName) => {
        return(
            <Alert severity="error">
                The store is closed - Please remove <strong>{productName}</strong>
            </Alert>
        ) 
    }

    const GetError = (status,productName) => {
        switch(status){
          case "OutOfStock":
            return OutOfStock(productName)
          case "StoreClosed":
            return StoreClosed(productName)
          default:
            return <div> Error </div>
        }
    }

    return err.map((error,index) => {
        return GetError(error.status,error.productName)
    })
    
}