import React, {useContext, useState, useEffect} from 'react';
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {GET_QR_DETAILS, GET_STORE_DETAILS} from 'query'
import { osName } from "react-device-detect";
import _isEmpty from 'lodash-es/isEmpty';
import _isNil from 'lodash-es/isNil'
import dayjs from 'dayjs'

const StoreContext = React.createContext(null);

const StoreProvider = ({children}) => {
  const history = useHistory();
  const aoplloClient = useApolloClient();
  const [ loading, setLoading ] = useState(true)
  const [ qrCodeData, setQrCodeData ] = useState({})
  const [ currentStore, setCurrentStore ] = useState({})
  const [ categories, setCategories ] = useState([]); // temp
  const [ products, setProducts ] = useState([]);
  const [ isSearchOpen, setIsSearchOpen ] = useState(false)
  const [ deviceOS, setDeviceOS ] = useState('')
  const [ isFirstLoad,setIsFirstLoad ] = useState(false)
  const [ isDialogShow, setIsDialogShow ] = useState(false)

  useEffect(() => {
    init()
  }, [loading])

  useEffect(() => {},[isDialogShow])

  const init = async () => {
    let qrCode = localStorage.getItem('qr');
    let qrExpire = localStorage.getItem('qr-exp-date');

    if(loading && _isEmpty(setQrCodeData) ){ 
      getQRDetails(qrCode, qrExpire) 
    }
    setDeviceOS(osName)

  }

  const isQRExpired = async () => {
    let qrExpire = localStorage.getItem('qr-exp-date');

    if(qrExpire === "null"){return }
    
    if(dayjs().isAfter(dayjs(qrExpire))){// check if expired 
      localStorage.removeItem('cart');
      history.push('/qrExpired')
    } 
  }

  const getQRDetails = async (qr_code,qrExpire) => {
    isQRExpired()
    if(qr_code){
      try{
        let result = await aoplloClient.query({
          query: GET_QR_DETAILS,
          variables: {qr_code},
        })
        let qrData = result.data.getStoreByQrCode
        // set qrcode expire 
        if(qrExpire === "null" && (!_isNil(qrData.timer) && qrData.timer !==0)){
          localStorage.setItem('qr-exp-date', dayjs().add(qrData.timer, 'minute'));
        } 
        // set qr code data to context
        setQrCodeData(qrData)
      } catch(err){
        throw err
      }
    }
    setLoading(false)
  }

  const getStoreDetails = async (storeID) => {
    if(storeID){
      try{
        const result = await aoplloClient.query({
          query: GET_STORE_DETAILS,
          variables: { storeID: storeID},
        })
        const store = result.data.storeV2

        setProducts(store.products)
        setCategories(store.categories)
        setCurrentStore({...store})
      } catch(err){
        console.log(err)
        throw err
      }
    }
  }

  return (
    <StoreContext.Provider 
      value={{
        categories, products,
        loading, qrCodeData, currentStore,
        getStoreDetails, isQRExpired,
        isSearchOpen, setIsSearchOpen,
        deviceOS,setQrCodeData,
        getQRDetails,
        isFirstLoad,setIsFirstLoad,
        isDialogShow, setIsDialogShow
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}


const useStore = () => {
  const store = useContext(StoreContext);
  if (store == null) {
    throw new Error('useStore() called outside of a AuthProvider?');
  }
  return store;
};

export {StoreProvider, useStore};