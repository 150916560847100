import React from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter} from 'react-router-dom';
import createApolloClient from './lib/apollo'
import { ApolloProvider } from '@apollo/client';
import { FirebaseAuthProvider, useFirebaseAuth } from './contexts/FirebaseAuthProvider'
import { StoreProvider } from './contexts/StoreProvider'
import { ProductProvider } from 'contexts/ProductProvider'
import { CurrentUserProvider } from './contexts/CurrentUserProvider'
import { CartProvider } from './contexts/CartProvider'
import { ConfirmProvider } from 'material-ui-confirm';
import { useTheme } from './theme/theme'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from '@date-io/dayjs';
import RouterView from './router'
import { PaymentProvider } from 'contexts/PaymentProvider';

// import "fontsource-roboto" 

function AppSetup() {
  // console.log("AppSetup")
  const {IdToken} = useFirebaseAuth()
  const client = createApolloClient(IdToken);
  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <CurrentUserProvider>
          <StoreProvider>
            <ProductProvider>
              <CartProvider>
                <PaymentProvider>
                  <ConfirmProvider>
                    <MuiPickersUtilsProvider utils={dayjs}>
                      <ThemeProvider theme={useTheme}>
                        <RouterView />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </ConfirmProvider>
                </PaymentProvider>
              </CartProvider>
            </ProductProvider>
          </StoreProvider>
        </CurrentUserProvider>
      </BrowserRouter>  
    </ApolloProvider>
  )
}

export default function () {
  // console.log('App Init')
  return (
    <FirebaseAuthProvider>
      <AppSetup />
    </FirebaseAuthProvider>
  )
}
