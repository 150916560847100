import React, {useRef,useEffect} from 'react';
import Icon from '@material-ui/core/Icon';
import { Grid, Typography, Dialog, Paper,Card, CardContent, SvgIcon} from '@material-ui/core';
import { useStyles } from './../../styles';
import { useStore } from 'contexts/StoreProvider'
import _get from 'lodash-es/get'
import ReadMoreAndLess from 'react-read-more-less';
import AwesomeSlider from 'react-awesome-slider';
import './../../theme/awesome-slider.css'

import {ProductOption} from './ProductOption'
export const ProductDetails = ( {product, isModalOpen, handleClose, editData = null} ) => {
  const classes = useStyles();
  const readMoreRef = useRef();

  const images = _get(product,['main_image'],[])
  const ImageSlider = () => {
    let images = [_get(product,['main_image'],[])]
    if(product.images && product.images.length > 0){
      product.images.map((img,i) => {
        images.push(img.url)
        return
      })
    }
    if(images.length <= 0) return <div />

    return(
      <>
      <AwesomeSlider 
        bullets={true} style={{marginBottom:20}}>
        {images.map((img,i) => {
          return <div key={i.toString()} data-src={img} />
        })}
      </AwesomeSlider>
      </>
    )  
  }

  if (!product && product == null) return <div />

  return(
    <Dialog fullScreen open={isModalOpen} onClose={handleClose}>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={10} md={6} >
            <Icon style={{
              zIndex:10,
              top:20,
              left:20,
              position:"absolute",
            }} onClick={handleClose} fontSize="large" >
              <img className={classes.imageIcon} src="/img/close-icon.svg"/>
            </Icon>
          <Grid> {images !== null ? <ImageSlider/> : <div style={{paddingTop:'100px'}}/>} </Grid>

          <Card style={{paddingTop:'10px',paddingBottom:'10px'}}>
            <Typography variant="h5" className={classes.ProductTitle}>
              { product.name }
            </Typography>
            <CardContent style={{marginLeft:5,marginRight:5}}>
              <ReadMoreAndLess
                ref={readMoreRef}
                className="read-more-content"
                charLimit={100}
                readMoreText=" ...more"
                readLessText="less"
              >
                {product.description}
              </ReadMoreAndLess>
            </CardContent>
          </Card>
          <Paper style={{display: 'flex',flexDirection: 'column',}}>
            <ProductOption
              product={product}
              editData={editData}
              handleClose={handleClose}
            />
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  )
}