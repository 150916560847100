import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import _isEmpty from 'lodash-es/isEmpty'
import { useCart } from './../../contexts/CartProvider';
import { useStore } from '../../contexts/StoreProvider';

export default () => {
    const { orderType, setOrderType } = useCart();
    const { qrCodeData } = useStore();
    const [ selectedOrderType, setSelectedOrderType ] = useState('')
    
    useEffect(() => {
        if(!_isEmpty(qrCodeData) || qrCodeData === null ){
            setOrderType(qrCodeData.type[0])
            setSelectedOrderType(qrCodeData.type[0].value)
        }
    }, [qrCodeData])

    useEffect(() => {console.log(orderType)},[orderType])

    const handleChange = event => {
        setSelectedOrderType(event.target.value)
        const newValue = event.target.value
        const selectedOption = qrCodeData.type.find(({value}) => value === newValue);
        setOrderType(selectedOption)
    };

    if(_isEmpty(qrCodeData) || qrCodeData === null || orderType === undefined) return <div />
    
    return(
        <TextField
            id="orderType"
            name="orderType"
            variant="outlined"
            select
            value={selectedOrderType}
            // defaultValue="dine-in"
            label="Order Type"
            onChange={handleChange}
            style={{width:'100%'}}
        >

        {
            qrCodeData.type.map((type,index) => {
                return (
                    <MenuItem key={index.toString()} value={type.value}>
                        {type.label}
                    </MenuItem>
                )
            })
        } 
        </TextField>
    )
        
}



