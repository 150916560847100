import { gql } from '@apollo/client';

const PAYMENT_ATTRIBUTES = gql`
  fragment paymentsStatusAttributes on PaymentStatus {
    status
    errorMsg
  }
`
export const CHECK_PAYMENT = gql`
  query ($paymentType:String, $orderNo: String){
    checkPaymentStatus(paymentType:$paymentType, orderNo: $orderNo){
      ...paymentsStatusAttributes
    }
  }${PAYMENT_ATTRIBUTES}
`;

export const WATCH_PAYMENT = gql`
  subscription ($paymentRefNo: String){
    payment(paymentRefNo: $paymentRefNo){
      _id
      payment_status
      payment_ref_no
      payment_type
      store_id
      bill_id
      bill_no
      amount_paid
    }
  }
`;
