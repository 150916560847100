import React from 'react';
import { Grid, Card, CardContent, Typography, AppBar, Dialog, 
         Toolbar ,IconButton, Divider, Chip
        } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dayjs from 'dayjs';
import Config from 'lib/config'
import CartItem from 'components/Cart/CartItem';
import {WhatsAppButton} from 'components/Button/WhatsAppButton'
import { useStyles } from 'styles';

export const HistoryDetails = ({ isModalOpen, handleClose, order ,products, whatsapp, collectTime,orderType}) => {
    const classes = useStyles();
    const HistoryProducts = ({products}) => {
        return products.map((items,index) => {
            return <CartItem 
                        key = {index.toString()}
                        name = {items.name}
                        quantity = {items.qty}
                        products = {items.products}
                        totalPrice = {items.total_price}
                    />
        })
    }

    return(
        <>
        <Dialog fullScreen open={isModalOpen} onClose={handleClose} >
            <AppBar position="static" style={{backgroundColor:'#fff'}}>
                <Toolbar>
                    <IconButton edge="start" style={{color:'#000'}} onClick={handleClose} aria-label="close">
                        <ExpandMoreIcon />
                    </IconButton>
                    <Typography color="textPrimary" align="center" style={{width:'100%'}}> 
                        {dayjs(order.created_date).format('DD MMM YYYY [at] hh:mm A')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={0} justify="center" style={{height:'100%'}}>
                <Grid 
                    item xs={12} sm={10} md={6} 
                    className={classes.MainHistory}
                >
                    <Card className={classes.CardHeader} >
                        <CardContent>
                            <Grid style={{display:'flex',width:'100%'}}>
                                <Typography className={classes.FontBold} style={{width:'70%'}}>
                                    {order.store_name}
                                </Typography> 
                                <Typography className={classes.billNo}> 
                                    #{order.bill_no}
                                </Typography>
                            </Grid>   
                            <Grid style={{display:'flex',width:'100%'}}>
                                <Chip label={order.order_type_label} color="primary" size="small"/>
                                {
                                order.payment_status === false
                                ? <Chip label="Unpaid" color="default" size="small"/> 
                                : <Chip label={order.payment_type} color="secondary" size="small"/> 
                                }  
                            </Grid>                 
                        </CardContent>
                    </Card>
                    <Card className={classes.CardHeader} xs={12} sm={10} md={6}>
                        {
                            orderType === 'self-pickup' || orderType === 'self-pickup-time-carplate' || orderType === 'self-pickup-time'
                            ? <CardContent style={{display:'flex',width:'100%'}}>
                                    <Typography style={{width:'50%'}} className={classes.FontBold}>
                                        Time to collect
                                    </Typography>
                                    <Typography style={{width:'50%'}}>
                                        {dayjs(collectTime).format('MM/DD/YYYY h:mm A')}
                                    </Typography>
                                </CardContent>
                            : <div />
                        }
                        <CardContent>
                            <Typography className={classes.FontBold}>
                                Order Summary
                            </Typography>
                            <Divider />
                            <HistoryProducts products={products} />
                        </CardContent>
                    </Card>
                    <Card className={classes.BottomCard}>
                        <CardContent style={{display:'flex'}}>
                            <Typography className={classes.TotalAmountLabel}>
                                Total Amount
                            </Typography>
                            <Typography className={classes.showTotalAmount}>
                                RM {parseFloat(order.total_price).toFixed(2)}
                            </Typography>
                        </CardContent>
                        <CardContent>
                            {dayjs(order.created_date).add(Config.SHOW_WHATSAPP_WITHIN, 'day').isAfter(dayjs())
                                && <WhatsAppButton whatsAppNumber={whatsapp} billNo={order.bill_no}/>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Dialog>
        </>
    )   
}