import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';
import { useStyles } from './../../styles';

export default ({name,quantity,products = [],totalPrice, removeFromCart = null, editCart = null}) => {
    
    const classes = useStyles();
    return (
        <Card style={{display:'flex'}}>
            <CardContent style={{width:'10%'}}>
                <Typography color="primary" className={classes.FontBold}> {quantity}x </Typography>
            </CardContent>
            <CardContent style={{width:'70%'}}>
                <Typography className={classes.FontBold}> {name} </Typography>
                {
                    products.filter(product => product.selected === 0).map((product, key) => {
                        return(
                            <div key={key}>
                                {
                                    product.selected.map((select, i) => {
                                        return <Typography key={i} style={{display:'block'}}>
                                        { select.name } 
                                        </Typography>
                                    })
                                }
                            </div>
                        )
                    })
                }
                <div> {editCart !== null ? editCart : <div />} </div>
            </CardContent>
            <CardContent style={{ display:'flex',alignItems:'flex-end',flexDirection: 'column', width:'20%'}} >
                <Typography> {parseFloat(totalPrice).toFixed(2)} </Typography>
                {removeFromCart !== null ? removeFromCart : <div />}
            </CardContent>
        </Card>
    )
}