import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

export const EmptyCart = () => {
    return(
        <Grid style={{paddingTop:50}}> 
            <ShoppingCartIcon style={{width:'100%',alignItems:'center',flexDirection:'column',marginBottom:10,fontSize:50}} />
            <Typography variant="h5" align="center"> Cart is Empty </Typography>
        </Grid>
    )
}