import React, {useState,useEffect} from 'react';
import { Radio, RadioGroup, FormControlLabel, FormHelperText } from '@material-ui/core';
import _isEmpty from 'lodash-es/isEmpty'
import { useCart } from './../../contexts/CartProvider';
import { useStore } from './../../contexts/StoreProvider';
import _get from 'lodash-es/get'
import { isMobile } from 'react-device-detect';

export default () => {
    const { qrCodeData } = useStore();
    const { payment, setPayment } = useCart();

    useEffect(() => {
        if(!_isEmpty(qrCodeData) || qrCodeData === null ){
            setPayment(qrCodeData.payment_types[0].value)
        }
    }, [qrCodeData])

    const ChoosePayment = ({paymentOption}) => {
        if(_isEmpty(qrCodeData.payment_types)) 
            return <FormControlLabel value="cash" control={<Radio />} label="Cash" /> 

        return paymentOption.map(({ label, value }, index) => (
            <div key={index}>
                <FormControlLabel 
                    value={value} 
                    control={<Radio />} 
                    label={label} 
                    disabled={value == 'boost' || (!isMobile && value ==='H5-Spay')}
                />
                {(!isMobile && value ==='H5-Spay') && <FormHelperText error={!isMobile && value ==='H5-Spay'}>Desktop is not allow for SarawakPay</FormHelperText>}
            </div>
            )
        )
    }

    return(
        <RadioGroup
            name="paymentTypes"
            onChange={({target: { value }}) => setPayment(value)}
            value= {payment}
        >
        <ChoosePayment paymentOption={qrCodeData.payment_types} />      
        </RadioGroup>
    )
}