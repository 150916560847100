import React, {useState, useEffect} from 'react';
import { Grid,Typography,Paper } from '@material-ui/core'
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import ScrollSpyTabs from "lib/ScrollSpyTabs";
import {useStore} from 'contexts/StoreProvider';
import ProductItem from 'components/Product/ProductItem'
import './../../index.css'

export const Categories = ({handleOpen}) => {
  const { products, categories } = useStore();
  const [categoryList, setCategoryList ] = useState([])

  useEffect(()=>{
    getCategoriesList()
  },[categories])

  const getCategoriesList = () => {
    let newList = []
    let currentProducts = []
    console.log(categories)
    for (let i = 0; i < categories.length; i++) {
      const cid = categories[i]._id ? categories[i]._id : i.toString()
      currentProducts = products.reduce((acc, curr) => curr.categories.includes(cid) ? [...acc ,curr] : acc, [])
      if(currentProducts.length !== 0){
			  newList.push({
          text: categories[i].display_name,
          component:(<Paper style={{marginTop:10}}>  
          <Typography variant="h5" style={{padding:10,paddingRight:0,fontWeight:'bold'}}>
            {categories[i].display_name}
          </Typography> 
          <ProductItem currentProducts={currentProducts} handleOpen={handleOpen}/>  
        </Paper>)
        })
      } 
    }
	 setCategoryList(newList)
  }

  // const RenderComponent = (display_name,currentProducts) => 
    
  // )

  const RenderEmptyList = () => (
	<Grid style={{paddingTop:50}}> 
		<ShoppingBasketOutlinedIcon style={{width:'100%',alignItems:'center',flexDirection:'column',marginBottom:10,fontSize:50}} />
		<Typography variant="h5" align="center"> No product found </Typography>
	</Grid>	
  )

  if(categoryList.length <= 0) return <RenderEmptyList />

  return <ScrollSpyTabs tabsInScroll = {categoryList} />
}
