import React, { useState, useEffect} from 'react';
import { Grid, Button, Checkbox, Typography, FormControlLabel, FormLabel, FormControl, RadioGroup, 
         Radio, Card, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from './../../styles';
import { useCart } from 'contexts/CartProvider';
import { useStore } from 'contexts/StoreProvider';
import { useProduct } from 'contexts/ProductProvider';
import _get from 'lodash-es/get';
import { useHistory, useParams } from 'react-router-dom';


const defaultOptions = option_groups => option_groups.reduce((acc, curr) => {
  const { _id, group_name, min_select, max_select } = curr
  const min = parseInt(min_select)
  const max = parseInt(max_select)
  return (
    [ 
      ...acc, 
      {
        group_id: _id,
        group_name,
        price: '0',
        optional: !(min > 0),
        min,
        max,
        selected: []
      } 
    ]
  )
}, [])

export const ProductOption = ({ product, handleClose, editData = null }) => {

  const classes = useStyles();
  const history = useHistory();
  const { storeID } = useParams();
  const { setKeyword } = useProduct()
  const { isSearchOpen, setIsSearchOpen } = useStore()
  const { addToCart, updateCart } = useCart()
  const [ order, setOrder ] = useState({})
  const [ validated, setValidated ] = useState(true)

  useEffect(() => {
    if ( product ) {
      const { _id, name, price, option_groups } = product
      setOrder({
        product_id: _id, 
        name,
        price, // orignal price
        options: editData ? editData.options : (option_groups ? defaultOptions(option_groups) : []),
        quantity: editData ? editData.quantity : 1,
        netPrice: editData ? editData.netPrice : price, // summation price of options and orignal price
        totalPrice: editData ? editData.totalPrice : price, // total price of summation times quantity (netPrice * quantity),
        product, // to edit order purpose
      })

      if ( option_groups ) {
        if ( !editData && option_groups.length !== 0 ) {
          option_groups.every(group => {
            if ( parseInt(group.min_select) > 0 ) {
              setValidated(false)
              return false
            }
            setValidated(true)
            return true
          })
        } else {
          setValidated(true)
        }
      }
    }
  }, [product, editData])

  const handleSearchClose = () => {
    setKeyword('')
    setIsSearchOpen(false)
  }

  const makeOrder = () => {
    addToCart(order)
    handleClose()
    isSearchOpen && handleSearchClose()
  }

  const changeOrder = () => {
    updateCart({ ...order, id: editData.id })
    handleClose()
  }

  const addQty = () => {
    const newQuantity = order.quantity + 1
    updateQty(newQuantity)
  }

  const deductQty = () => {
    const newQuantity = order.quantity - 1
    updateQty(newQuantity)
  }

  const updateQty = qty => {
    const newPrice = order.netPrice * qty
    setOrder({ 
      ...order, 
      quantity: qty, 
      totalPrice: newPrice.toString()
    })
  }

  const updateOrder = newOptions => {
    const newNetPrice = newOptions.reduce((acc, curr) => acc + parseFloat(curr.price), parseFloat(order.price))
    setOrder({
      ...order,
      options: newOptions,
      netPrice: newNetPrice.toString(),
      totalPrice: (newNetPrice*order.quantity).toString()
    })

    if (newOptions.length !== 0) {
      newOptions.every(option => {
        if ( parseInt(option.min) > 0 && option.selected.length < parseInt(option.min) ) {
          // if minimum select is more than 0
          // user must select at least one option
          setValidated(false)
          return false
        }
        if ( parseInt(option.max) !== 0 && option.selected.length > parseInt(option.max) ) {
          setValidated(false)
          return false
        }
        setValidated(true)
        return true
      })
    }
  }

  const updateOption = ( optionName, optionPrice, groupId, multiple, onlyOne, max_select ) => {
    const newOptions = order.options.reduce((acc, curr) => {
        if ( curr.group_id === groupId ) {
          if ( multiple || onlyOne ) {
            // add new option to current option
            const checkSelected = curr.selected.find(select => select.name === optionName)
            const newSelected = (!checkSelected) ?
              // add option if option is not selected
              [
                ...curr.selected,
                {
                  name: optionName,
                  price: optionPrice
                }
              ] :
              // remove option if option is not selected
              curr.selected.filter(select => select.name !== optionName )

              if(newSelected.length > max_select){
                alert(`You can only select ${max_select} item`)
                return [ ...acc, curr ]
              }

            const newSelectedPrice = newSelected.reduce((acc, curr) => acc + parseFloat(curr.price) , 0)
            curr = {
              ...curr,
              price: newSelectedPrice.toString(),
              selected: newSelected,
            }
            
          } else {
            // only one can selected
            //  replace new option with current option
            curr = {
              ...curr,
              price: optionPrice,
              selected: [
                {
                  name: optionName,
                  price: optionPrice
                }
              ],
            }
          }
        }
        return [ ...acc, curr ]
      }, [])
    updateOrder(newOptions)
  }

  const showPrice = (price) => {
    if(parseFloat(price) > 0){
      return `+ ${parseFloat(price).toFixed(2)}`
    } else if(parseFloat(price) < 0) {
      return `${parseFloat(price).toFixed(2)}`
    } else {
      return ''
    }
  }

  const RadioOption = ({option_items, id, findOrderOption}) => {
    return option_items.map((option, index) =>{
      return(
        <RadioGroup
          key = {index.toString()} 
          value= {_get(findOrderOption,['selected',0,"name"],'')}
          name= {option.name}
          onChange={ () => updateOption(option.name, option.price, id, false) }
        >
          <div style={{display:'flex',width:'100%'}}>
            <FormControlLabel value={option.name} control={<Radio />} style={{display:'flex',width:'100%'}}
              label= {option.name}
              />
            <div className={classes.OptionPrice}>{showPrice(option.price)}</div>
          </div>
        </RadioGroup>
      )   
    })
  }
  
  const CheckboxOption = ({option_items, id, findOrderOption, max_select, multiple,onlyOne}) => {
    return option_items.map((option, index) =>{
      const findSelected = findOrderOption ? findOrderOption.selected.find(select => select.name === option.name) : false
      return (
        <div key = {index.toString()} style={{display:'flex',width:'100%'}}>
          <FormControlLabel style={{display:'flex',width:'100%'}}
            control={
              <Checkbox 
                name="checkbox"
                value= {option.name} 
                checked={ findSelected ? true : false }
                onChange={ () => updateOption(option.name, option.price, id, multiple, onlyOne,max_select) }
                disabled={
                  max_select !== 0 
                  && onlyOne > 1
                  && multiple 
                  && !findSelected 
                  && (findOrderOption && findOrderOption.selected.length >= max_select) }
            />}
            label={option.name}
          />
          <div className={classes.OptionPrice}> {showPrice(option.price)}</div>
        </div>
      )
    })
  }

  const OptionGroupItem =({option_groups}) => { 
    return option_groups.map((option_group, index) =>{
      const { _id, group_name, min_select: minSelect, max_select: maxSelect, options } = option_group
      const min_select = parseInt(minSelect)
      const max_select = parseInt(maxSelect)
      const multiple = max_select !== 1
      const optional = min_select === 0
      const onlyOne = max_select === 1 && optional
      const findOrderOption = order.options ? order.options.find(option => option.group_id === _id) : false

      return (
          <FormControl component="fieldset" key={index.toString()} className={classes.formControl} style={{paddingLeft:'10px'}}>
            <FormLabel component="legend" style={{fontWeight:'bold', color:'#000',paddingTop:20}}> 
              { group_name } <br />
              {
                (!optional || multiple || onlyOne) &&
                <Typography variant="caption">
                  { !optional && `Please select ${max_select}` }
                  { multiple && ( max_select !== 0 ? `Maximum ${ max_select }` : 'Unlimited selection') }
                  { onlyOne && 'Optional' }
                </Typography>
              }
            </FormLabel>
              {multiple || onlyOne
              ? 
              <CheckboxOption 
                option_items={options}
                id={_id}
                findOrderOption={findOrderOption}
                max_select={max_select}
                multiple={multiple}
                onlyOne = {onlyOne}
              />
              : 
              <RadioOption 
                option_items={options} 
                id={_id}
                findOrderOption={findOrderOption}
              />
              }
          </FormControl>
        )  
    })
  }

  const SpecialInstruction = () => {
    return(
      <div style={{margin:8}}>
        <Typography style={{fontWeight:'bold'}}>Special Instruction</Typography>
        <TextField
          id="special_instruc"
          name="special_instruc"
          placeholder="E.g. No onions, please"
          multiline = { true }
          rows={2}
          variant="outlined"
          value={_get(order,['remark'],'')}
          onChange={(event) => setOrder({ ...order, remark: event.target.value })}
          inputProps={{ maxLength: 100 }}
          style={{width:'100%'}}
        />
      </div>
    )
  }

  if (!product && product == null) return <div />

  return(
    <>
      <OptionGroupItem option_groups={product.option_groups} />
      {SpecialInstruction() }
      <Grid container style={{justifyContent:'center',marginBottom:20}}>
        <Button 
          style={{color:'#d50000',background:'transparent'}}
          onClick={ deductQty }
          disabled={order.quantity === 0}
        >
          <RemoveIcon fontSize="default"/>
        </Button>
          <Typography variant="h6" style={{marginLeft:30,marginRight:30}}>
            { order.quantity }
          </Typography>
        <Button 
          style={{color:'#2ECC71',background:'transparent'}}
          onClick={ addQty }
        >
          <AddIcon fontSize="default"/>
        </Button>
      </Grid>
      <Grid container style={{justifyContent:'center',width:'100%',borderTopStyle: 'solid',borderWidth: 'thin', borderColor:'#dedede'}}>
        <Card style={{margin:'5%',width:"90%"}}>
          <Button 
            disabled={!validated || order.quantity === 0}
            className = {classes.btnAddCart}
            variant="contained"
            onClick={!editData ? makeOrder : changeOrder}
          >{ !editData ? 'Add to Cart' : 'Update Order' }  RM { parseFloat(order.totalPrice).toFixed(2)}
          </Button>
        </Card>
      </Grid>
    </>
  )
}