import React, {useEffect, useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty'
import _get from 'lodash-es/get'
import { DateTimePicker } from "@material-ui/pickers";
import { Typography,FormControlLabel,Checkbox, TextField, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import {useCurrentUser} from 'contexts/CurrentUserProvider';
import {useCart} from 'contexts/CartProvider'
import dayjs from 'dayjs'

export default ({currentUser, onChange, helperText, error}) => {
    const [ selectedDate, setSelectedDate ] = useState(new Date())
    const { setNewUserData } = useCart();

    return(
        <>
            <TextField 
                multiline
                type="text"
                label="Address"
                variant='outlined'
                name="address"
                id="address"
                helperText={helperText}
                error={error}
                onChange={onChange}
                defaultValue={_get(currentUser,['address'],'')}
                style={{width:"100%",marginTop:10}}
            />
            
            <TextField
                type="text"
                label="Postcode"
                variant='outlined'
                name="postcode"
                id="postcode"
                helperText={helperText}
                error={error}
                onChange={onChange}
                defaultValue={_get(currentUser,['postcode'],'')}
                style={{width:"100%",marginTop:10}}
            />
                        
            <TextField
                type="text"
                label="City"
                variant='outlined'
                name="city"
                id="city"
                helperText={helperText}
                error={error}
                onChange={onChange}
                defaultValue={_get(currentUser,['city'],'')}
                style={{width:"100%",marginTop:10}}
            />
            
            <TextField
                variant='outlined'
                type="text"
                label="State"
                name="state"
                id="state"
                helperText={helperText}
                error={error}
                onChange={onChange}
                defaultValue={_get(currentUser,['state'],'')}
                style={{width:"100%",marginTop:10}}
            />
        </>
    )
}